import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'GothamRounded';
        font-style: normal;
        font-weight: 300;
        src: url('https://images.joinstudyhall.com/font/GothamRndSSm-Light.otf') format('opentype');
      }
      @font-face {
        font-family: 'GothamRounded';
        font-style: normal;
        font-weight: 500;
        src: url('https://images.joinstudyhall.com/font/GothamRndSSm-Book.otf') format('opentype');
      }
      @font-face {
        font-family: 'GothamRounded';
        font-style: normal;
        font-weight: 700;
        src: url('https://images.joinstudyhall.com/font/GothamRndSSm-Medium.otf') format('opentype');
      }
      @font-face {
        font-family: 'GothamRounded';
        font-style: normal;
        font-weight: 900;
        src: url('https://images.joinstudyhall.com/font/GothamRndSSm-Bold.otf') format('opentype');
      }
      `}
  />
);

export default Fonts;
