/* eslint-disable @typescript-eslint/no-empty-function */
import { countries } from 'countries-list';
import { createContext, useContext } from 'react';

import { CountryType } from '@/components/common/RegistrationHeader/types';

type Country = {
  country: CountryType;
  setCountryInfo: (c: CountryType) => void;
};

const { GB } = countries;

export const countryInitialValues = {
  name: GB.name,
  flag: GB.emoji,
  phone: GB.phone,
  codeName: 'GB',
};

export const CountryContext = createContext<Country>({
  country: countryInitialValues,
  setCountryInfo: () => {},
});

export const useCountryContext = () => useContext(CountryContext);
