import { PROFILE_URL } from '../paths';
import { useBaseQuery } from '../utils';
import { LearnerProfileDto } from './types';

export const fetchUserProfile = <T = any>(
  enabled: boolean,
  userId?: string,
  transformFn?: (data: LearnerProfileDto) => T
) =>
  useBaseQuery<LearnerProfileDto, T>(
    PROFILE_URL,
    userId ? ['profile', userId] : ['profile'],
    {} as LearnerProfileDto,
    (response) => response, // LearnerProfileDtoSchema.parse(response),
    transformFn,
    {
      enabled,
    }
  );
