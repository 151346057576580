import { colors } from '@/styles/colors';

export const ProgressStyles = {
  variants: {
    gray: () => ({
      filledTrack: {
        bg: `${colors.brand.gray700}`,
        borderRadius: '27px',
        height: '8px',
      },
      track: {
        bg: `${colors.brand.gray100}`,
        borderRadius: '27px',
        height: '8px',
      },
    }),
  },
};
