import { ComponentStyleConfig, RadioProps } from '@chakra-ui/react';

import { custom } from '@/styles/custom';

const sizes = {
  sm: `${custom.font.xs}`,
  md: `${custom.font.sm}`,
  lg: `${custom.font.md}`,
};

export const RadioStyles: ComponentStyleConfig = {
  baseStyle: {
    control: {
      _checked: {
        _disabled: {
          color: 'brand.gray400',
          borderColor: 'brand.gray400',
          bg: 'transparent',
        },

        '&::before': {
          position: 'absolute',
          width: '8px',
          height: '8px',
        },
      },
      _disabled: {
        borderColor: 'brand.gray400',
        bg: 'transparent',
      },
      _focus: {
        outline: '0px',
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
      },
    },
  },
  variants: {
    primary: ({ size }: RadioProps) => ({
      control: {
        width: '20px',
        height: '20px',
        bg: 'brand.white',
        border: '1.3px solid',
        borderColor: 'brand.gray500',

        _checked: {
          bg: 'brand.primary25',
          borderColor: 'brand.primary500',
          color: 'brand.primary500',

          _hover: {
            bg: 'brand.primary50',
            borderColor: 'brand.primary500',
            color: 'brand.primary500',
          },
        },
        _hover: {
          color: 'brand.primary700',
        },
      },
      label: {
        color: 'brand.gray900',
        fontWeight: 700,
        fontSize: sizes[size as keyof typeof sizes] || sizes.md,
      },
    }),
    defaultAnswer: (props: RadioProps) => ({
      container: {
        height: '40px',
        padding: '16px 18px',
        boxShadow: '0px 0px 12px 6px rgba(51, 56, 62, 0.03)',
        borderRadius: '24px',
        width: '100%',
        bg: 'brand.white',
      },
      control: {
        width: '20px',
        height: '20px',
        bg: 'brand.white !important',
        border: '1.5px solid',
        borderColor: `${
          props.isDisabled ? 'brand.gray500!important' : 'brand.gray900'
        }`,

        _checked: {
          bg: 'brand.white',
          borderColor: 'brand.gray900',
          color: 'brand.gray900',

          _hover: {
            bg: 'brand.white',
            borderColor: 'brand.gray900',
            color: 'brand.gray900',
          },
        },
      },
      label: {
        color: `${props.isDisabled ? 'brand.gray500' : 'brand.gray800'}`,
        fontSize: `${custom.font.md}`,
        paddingLeft: '3px',
      },
    }),
    corePlan: (props: RadioProps) => ({
      control: {
        width: '21px',
        height: '21px',
        bg: 'brand.white !important',
        border: '1.3px solid',
        borderColor: `${
          props.isDisabled ? 'brand.gray500!important' : 'brand.success800'
        }`,

        _checked: {
          bg: 'brand.white',
          borderColor: 'brand.success800',
          color: 'brand.success800',

          _hover: {
            bg: 'brand.white',
            borderColor: 'brand.success800',
            color: 'brand.success800',
          },
        },
      },
    }),
    premiumPlan: (props: RadioProps) => ({
      control: {
        width: '21px',
        height: '21px',
        bg: 'brand.white !important',
        border: '1.3px solid',
        borderColor: `${
          props.isDisabled ? 'brand.gray500!important' : 'brand.blue800'
        }`,

        _checked: {
          bg: 'brand.white',
          borderColor: 'brand.blue800',
          color: 'brand.blue800',

          _hover: {
            bg: 'brand.white',
            borderColor: 'brand.blue800',
            color: 'brand.blue800',
          },
        },
      },
    }),
  },
  defaultProps: {
    variant: 'primary',
  },
};
