const customMediaQuery = (maxWidth: number): string =>
  `@media (max-width: ${maxWidth}px)`;

const customMinMaxMediaQuery = (minWidth: number, maxWidth: number): string =>
  `@media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;

const media = {
  custom: customMediaQuery,
  minmax: customMinMaxMediaQuery,
  laptop: customMediaQuery(1440),
  smallLaptop: customMediaQuery(1279),
  bigDesktop: customMediaQuery(1200),
  tabletLandscape: customMediaQuery(1024),
  desktop: customMediaQuery(922),
  tablet: customMediaQuery(768),
  phone: customMediaQuery(490),
};

export default media;
