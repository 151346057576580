import { ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '@/styles/colors';
import { custom } from '@/styles/custom';

export const DrawerStyles: ComponentStyleConfig = {
  sizes: {
    '4xl': () => ({
      width: 'calc(100vw - 80px)',
    }),
  },
  variants: {
    studyGuide: () => ({
      dialogContainer: {
        zIndex: 6,
      },
      overlay: {
        zIndex: 5,
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      dialog: {
        minWidth: '458px',
        marginRight: `${custom.rightSidebarWidth.desktop}`,
        shadow: 'none',
        borderRight: `1px solid ${colors.brand.gray200}`,
        '@media (max-width: 1279px)': {
          marginRight: `${custom.rightSidebarWidth.mobile}`,
        },
        '@media (max-width: 1024px)': {
          shadow: '0px 0px 24px 0px #D0D2D880',
          maxHeight: '360px !important',
          top: 'auto !important',
          borderRadius: '24px',
          minWidth: `calc(100% - ${custom.rightSidebarWidth.mobile} - 48px)`,
          margin: '24px 137px 24px 24px !important',
        },
        '@media (max-width: 768px)': {
          shadow: '0px 0px 24px 0px #D0D2D880',
          maxHeight: '360px !important',
          top: 'auto !important',
          borderRadius: '24px',
          minWidth: `calc(100% - 48px)`,
          margin: '24px 137px 24px 24px !important',
          marginRight: `24px !important`,
        },
      },
      body: {
        padding: '24px',
        margin: '0px',
        '@media (max-width: 1024px)': {
          width: '100% !important',
          marginRight: '10px !important',

          '&::-webkit-scrollbar': {
            backgroundColor: 'transparent',
            display: 'block',
            width: '8px',
            height: '8px',
            borderRadius: '50px',
          },

          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.brand.gray300,
            height: '8px',
            borderRadius: '50px',
          },

          '&::-webkit-scrollbar-track-piece:end': {
            backgroundColor: colors.brand.gray50,
            marginLeft: '12px',
            borderRadius: '50px',
            marginBottom: '12px',
          },

          '&::-webkit-scrollbar-track-piece:start': {
            backgroundColor: colors.brand.gray50,
            marginRight: '12px',
            borderRadius: '50px',
            marginTop: '2px',
          },
        },
      },
      header: {
        minHeight: '60px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        position: 'relative',
        margin: '0px 32px',
        padding: '0px',
        borderBottom: `1px solid ${colors.brand.gray200}`,
      },
      closeButton: {
        top: '18px',
        right: '0px',
        justifySelf: 'center',
        alignSelf: 'center',
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        backgroundColor: colors.brand.gray100,
        color: colors.brand.gray900,

        _hover: {
          backgroundColor: colors.brand.gray300,
        },
      },
    }),
    drawerMenu: () => ({
      body: {
        padding: '0px 48px',
        margin: '18px 0px',
      },
      header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        padding: '24px 48px',
      },
      closeButton: {
        position: 'initial',

        justifySelf: 'center',
        alignSelf: 'center',
        width: '24px',
        height: '24px',
        color: colors.brand.gray900,

        _hover: {
          backgroundColor: colors.brand.gray300,
        },
      },
    }),
    deepReading: (props) => {
      return {
        dialogContainer: {
          zIndex: 10,
          maxWidth: props.size === '4xl' ? `100vw` : 'max-content',
          left: 'auto',
          right: '0px',
          transition: 'none',
        },
        overlay: {
          display: 'none',
        },
        dialog: {
          minWidth: '458px',
          maxWidth: props.size === '4xl' ? `100vw` : '458px',
          transition: 'none',
          marginRight: `0px`,
          shadow: 'none',
          borderRight: `1px solid ${colors.brand.gray200}`,

          '@media (max-width: 768px)': {
            maxHeight: '100vh !important',
            top: '0 !important',
            borderRadius: '0px',
            minWidth: `100vw`,
            margin: '0 !important',
            marginRight: `0 !important`,
          },
        },
        body: {
          padding: '24px',
          margin: '0px',
          '&::-webkit-scrollbar': {
            backgroundColor: 'transparent',
            display: 'block',
            width: '8px',
            height: '8px',
            borderRadius: '50px',
          },

          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.brand.gray300,
            height: '8px',
            borderRadius: '50px',
          },

          '&::-webkit-scrollbar-track-piece:end': {
            backgroundColor: colors.brand.gray50,
            marginLeft: '12px',
            borderRadius: '50px',
            marginBottom: '12px',
          },

          '&::-webkit-scrollbar-track-piece:start': {
            backgroundColor: colors.brand.gray50,
            marginRight: '12px',
            borderRadius: '50px',
            marginTop: '2px',
          },
          '@media (max-width: 1024px)': {
            width: '100% !important',
            marginRight: '10px !important',
          },
        },
        header: {
          minHeight: '60px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          position: 'relative',
          margin: '0px 32px',
          padding: '0px',
          borderBottom: `1px solid ${colors.brand.gray200}`,
        },
        closeButton: {
          top: '18px',
          right: '0px',
          justifySelf: 'center',
          alignSelf: 'center',
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          backgroundColor: colors.brand.gray100,
          color: colors.brand.gray900,

          _hover: {
            backgroundColor: colors.brand.gray300,
          },
        },
      };
    },
    tableOfContents: () => ({
      dialogContainer: {
        zIndex: 201,
        maxWidth: '360px',
        left: '0',
        right: 'auto',
        transition: 'none',
      },
      overlay: {
        display: 'none',
      },
      dialog: {
        minWidth: '360px',
        marginTop: '100px',
        backgroundColor: colors.brand.gray10,
        transition: 'none',
        shadow: '8px 8px 16px 0px #D0D2D81C',
      },
      body: {
        padding: '24px 16px 24px 32px',
        margin: '0px',
        '@media (max-width: 1330px)': {
          padding: '24px 16px 24px 24px',
        },
        '&::-webkit-scrollbar': {
          backgroundColor: 'transparent',
          display: 'block',
          width: '8px',
          height: '8px',
          borderRadius: '50px',
        },

        '&::-webkit-scrollbar-thumb': {
          backgroundColor: colors.brand.gray300,
          height: '8px',
          borderRadius: '50px',
        },

        '&::-webkit-scrollbar-track-piece:end': {
          backgroundColor: colors.brand.gray50,
          marginLeft: '12px',
          borderRadius: '50px',
          marginBottom: '12px',
        },

        '&::-webkit-scrollbar-track-piece:start': {
          backgroundColor: colors.brand.gray50,
          marginRight: '12px',
          borderRadius: '50px',
          marginTop: '2px',
        },
        '@media (max-width: 1024px)': {
          width: '100% !important',
          marginRight: '10px !important',
        },
      },
      header: {
        position: 'relative',
        margin: '0px 16px 0 32px',
        padding: '0px',
        '@media (max-width: 1330px)': {
          margin: '0px 16px 0 24px',
        },
        borderBottom: `1px solid ${colors.brand.gray100}`,
      },
      closeButton: {
        position: 'relative',
        top: '0',
        left: '0',
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        marginBottom: '24px',
        backgroundColor: colors.brand.gray100,
        color: colors.brand.gray900,
        _hover: {
          backgroundColor: colors.brand.gray300,
        },
      },
    }),
    layoutDrawer: () => ({
      dialogContainer: {
        zIndex: 2,
        maxWidth: '453px',
        left: '0',
        right: 'auto',
        transition: 'none',
      },
      overlay: {
        display: 'none',
      },
      dialog: {
        minWidth: '453px',
        backgroundColor: colors.brand.gray10,
        transition: 'none',
        shadow: '8px 8px 16px 0px #D0D2D81C',
      },
      body: {
        padding: '24px 16px 24px 88px',
        margin: '0px',
        '@media (max-width: 1330px)': {
          padding: '24px 16px 24px 24px',
        },
        '&::-webkit-scrollbar': {
          backgroundColor: 'transparent',
          display: 'block',
          width: '8px',
          height: '8px',
          borderRadius: '50px',
        },

        '&::-webkit-scrollbar-thumb': {
          backgroundColor: colors.brand.gray300,
          height: '8px',
          borderRadius: '50px',
        },

        '&::-webkit-scrollbar-track-piece:end': {
          backgroundColor: colors.brand.gray50,
          marginLeft: '12px',
          borderRadius: '50px',
          marginBottom: '12px',
        },

        '&::-webkit-scrollbar-track-piece:start': {
          backgroundColor: colors.brand.gray50,
          marginRight: '12px',
          borderRadius: '50px',
          marginTop: '2px',
        },
        '@media (max-width: 1024px)': {
          width: '100% !important',
          marginRight: '10px !important',
        },
      },
      header: {
        position: 'relative',
        margin: '0px 16px 0 88px',
        padding: '0px',
        '@media (max-width: 1330px)': {
          margin: '0px 16px 0 24px',
        },
        borderBottom: `1px solid ${colors.brand.gray100}`,
      },
      closeButton: {
        position: 'relative',
        top: '0',
        left: '0',
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        marginBottom: '24px',
        backgroundColor: colors.brand.gray100,
        color: colors.brand.gray900,
        _hover: {
          backgroundColor: colors.brand.gray300,
        },
      },
    }),
  },
};
