/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react';

import { LearnerProfileDto } from '@/store/user-profile/types';

type Learners = {
  learners: LearnerProfileDto[];
  setLearnersInfo: (l: LearnerProfileDto[]) => void;
};

const learnersInitialValues = {
  givenName: '',
  surname: '',
  phoneNumber: '',
  email: '',
  dob: '',
  currentSchool: null,
  grade: null,
  gender: null,
  referralCode: '',
  targetSchools: [],
  exams: [],
  isEnglishSecondLanguage: false,
  specialEducationNeeds: [],
  planDetails: {
    plan: 'core',
    price: 0,
  },
};

export const LearnersContext = createContext<Learners>({
  learners: [],
  setLearnersInfo: () => {},
});

export const useLearnersContext = () => useContext(LearnersContext);
