import { colors } from '@/styles/colors';
import { custom } from '@/styles/custom';

export const AlertDialogStyles = {
  baseStyle: () => ({
    dialog: {
      borderRadius: '12px',
      width: '100%',

      '@keyframes fadeIn': {
        '0%': {
          opacity: 0,
          transform: 'translateY(5px)',
        },
        '100%': {
          opacity: 1,
          transform: 'translateY(0)',
        },
      },
      '@keyframes fadeOut': {
        '0%': {
          opacity: 1,
          transform: 'translateY(0)',
        },
        '100%': {
          opacity: 0,
          transform: 'translateY(5px)',
        },
      },
      '& .select__menu': {
        animation: 'fadeIn 0.25s ease-in-out !important',
      },
      '& .menu--close': {
        animation: 'fadeOut 0.25s ease-in-out !important',
      },
    },
    body: {
      height: '100%',
      padding: '0 24px 24px 24px',
      fontSize: `${custom.font.md}`,
      textAlign: 'center',
    },
    footer: {
      padding: `0  ${custom.space.xl}`,
      display: 'flex',
      margin: `0 0 ${custom.space.xl} 0`,
    },
    header: {
      textAlign: 'center',
    },
  }),

  variants: {
    singleAction: () => ({
      dialog: {
        width: '100%',
        borderRadius: '12px',
        maxWidth: '448px',
        margin: `0 ${custom.space.mdl}`,
      },
      body: {
        padding: `${custom.space.mdl}`,
      },
      footer: {
        gap: '0px',
      },
    }),
    twoActions: () => ({
      dialog: {
        width: '100%',
        borderRadius: '12px',
        maxWidth: '448px',
        margin: `0 ${custom.space.mdl}`,
      },
      body: {
        padding: `${custom.space.mdl}`,
      },
      footer: {
        gap: `${custom.space.md} `,
      },
    }),
    newLearner: () => ({
      dialogContainer: {},
      dialog: {
        height: '624px',
        borderRadius: '24px',
        bg: `${colors.brand.gray25}`,
        '@media (max-width: 768px)': {
          margin: '24px',
          marginRight: '44px',
          width: 'calc(100vw - 48px)',
        },
      },
      header: {
        padding: `${custom.space.xl} ${custom.space.xxxl}`,
        '@media (max-width: 768px)': {
          padding: '24px',
        },
      },
      footer: {
        gap: `${custom.space.md} `,
      },
      body: {
        padding: `${custom.space.xl} ${custom.space.xxxl}`,
        textAlign: 'normal',
        height: '624px',
        overflowX: 'hidden',
        overflowY: 'auto',
        '::-webkit-scrollbar': {
          backgroundColor: 'transparent',
          display: 'block',
          width: '8px',
          height: '8px',
          borderRadius: '50px',
        },

        '::-webkit-scrollbar-thumb': {
          backgroundColor: `${colors.brand.gray300}`,
          height: '8px',
          borderRadius: '50px',
        },

        '::-webkit-scrollbar-track-piece:end': {
          backgroundColor: `${colors.brand.gray50}`,
          marginLeft: '12px',
          borderRadius: '50px',
          marginBottom: '15px',
        },

        '::-webkit-scrollbar-track-piece:start': {
          backgroundColor: `${colors.brand.gray50}`,
          marginRight: '12px',
          borderRadius: '50px',
          marginTop: '15px',
        },
        '@media (max-width: 768px)': {
          padding: '24px',
        },
      },
    }),
    settingsModal: () => ({
      dialog: {
        boxShadow: `0 0 ${custom.space.xl} -1px rgba(208, 210, 216, 0.2)`,
        borderRadius: `${custom.space.xl} `,
        margin: 0,
        position: 'absolute',
        right: '100px',
        top: '100px',
        '@media (max-width: 1024px)': {
          right: '30px',
          top: '170px',
        },
      },
      header: {
        padding: `${custom.space.lg} 0 ${custom.space.lg} 0`,
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
      },
      body: {
        padding: ` 0 ${custom.space.xl} 0 ${custom.space.xl}`,
      },
      dialogContainer: {
        padding: 0,
        '@media (max-width: 769px)': {
          width: '100%',
          height: '100%',
        },
      },
      footer: {
        padding: `${custom.space.md} 0 0 0`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flex: 1,
      },
    }),
    learnerModal: () => ({
      dialogContainer: {
        '@media (max-width: 769px)': {
          width: '100%',
          height: '100%',
        },
      },
      dialog: {
        height: '576px',
        borderRadius: '24px',
        '@media (max-width: 768px)': {
          margin: '24px',
          marginRight: '24px',
          width: 'calc(100vw - 48px)',
        },
      },
      overlay: {
        '@media (max-width: 769px)': {
          width: '100%',
          height: '100%',
        },
      },
      body: {
        padding: ` 0 ${custom.space.xl} ${custom.space.xxl}`,
        textAlign: 'normal',
        height: '200px',
      },
    }),
    loaderModal: () => ({
      dialog: {
        width: '100%',
        maxWidth: '670px',
        height: '492px',
        margin: `0 ${custom.space.mdl}`,
      },
    }),
    addPhoto: () => ({
      dialogContainer: {},
      dialog: {
        width: '100%',
        maxWidth: '648px',
        borderRadius: '24px',
        margin: `0 ${custom.space.mdl}`,

        '@media (max-width: 1024px)': {
          maxWidth: '100%',
          height: '100%',
          borderRadius: '0',
          margin: '0',
        },
      },
    }),
    socialAction: () => ({
      dialogContainer: {},
      dialog: {
        width: '536px',
        minWidth: '536px',
        borderRadius: '24px',

        '@media (max-width: 768px)': {
          margin: '24px',
          marginRight: '24px',
          width: 'calc(100vw - 48px)',
          minWidth: 'calc(100vw - 48px)',
        },
      },
    }),
    signUpOptions: () => ({
      dialogContainer: {},
      dialog: {
        borderRadius: '24px',
      },
    }),
    studentApp: () => ({
      dialogContainer: {
        width: '100%',
      },
      dialog: {
        borderRadius: '24px',
        '@media (max-width: 768px)': {
          margin: '24px',
          width: 'calc(100vw - 48px)',
        },
      },
      overlay: {
        width: '100%',
        height: '100%',
      },
    }),
    noStudentAccessCode: () => ({
      dialogContainer: {},
      dialog: {
        maxWidth: '536px',
        borderRadius: '24px',
      },
    }),
    mockExamModal: () => ({
      dialogContainer: {
        '@media (max-width: 769px)': {
          height: '100%',
          width: '100%',
        },
      },
      overlay: {
        '@media (max-width: 769px)': {
          height: '100%',
          width: '100%',
        },
      },
      header: {
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
      },
      dialog: {
        maxWidth: '536px',
        borderRadius: '24px',
        '@media (max-width: 768px)': {
          marginRight: '24px',
          marginLeft: '24px',
        },
      },
    }),
    termsModal: () => ({
      dialogContainer: {
        overflow: 'hidden',
      },
      dialog: {
        width: '100%',
        maxWidth: '648px',
        maxHeight: '600px',
        borderRadius: '24px',
        margin: '0 24px',
      },
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
        overflow: 'scroll',
      },
    }),
    forgotPasswordModal: () => ({
      dialogContainer: {
        overflow: 'auto',
      },
      dialog: {
        width: '100%',
        maxWidth: '886px',
        maxHeight: '650px',
        borderRadius: '24px',
        margin: '85px 24px auto 24px',
        backgroundColor: 'transparent',
        boxShadow: 'none',

        '@media (max-width: 935px)': {
          maxWidth: '560px',
          maxHeight: '403px',
        },
      },
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
        overflow: 'scroll',
      },
    }),
    noMoreQuestionsModal: () => ({
      dialogContainer: {
        overflow: 'hidden',
      },
      dialog: {
        width: '100%',
        maxWidth: '752px',
        maxHeight: '600px',
        minHeight: '280px',
        borderRadius: '24px',
        margin: '0 24px',
        backgroundColor: `${colors.brand.blue25}`,
      },
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
        overflow: 'auto',
      },
    }),
    studentAssessmentModal: () => ({
      dialogContainer: {
        overflow: 'hidden',
      },
      dialog: {
        width: '100%',
        maxWidth: '536px',
        maxHeight: '592px',
        borderRadius: '24px',
        margin: '0 24px',
      },
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
      },
    }),
    singleStudentAssessmentModal: () => ({
      dialog: {
        width: '100%',
        maxWidth: '542px',
        maxHeight: '592px',
        borderRadius: '24px',
        margin: '0 24px',
      },
    }),
    historyGameModal: () => ({
      dialogContainer: {
        overflow: 'hidden',
      },
      dialog: {
        width: '100%',
        maxWidth: '880px',
        maxHeight: '592px',
        borderRadius: '24px',
        margin: '0 24px',
      },
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
      },
    }),
    dashboardReviewModal: () => ({
      dialogContainer: {
        overflow: 'hidden',
      },
      dialog: {
        width: '100%',
        maxWidth: '648px',
        maxHeight: '620px',
        borderRadius: '24px',
        margin: '0 24px',
      },
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
      },
    }),
    dashboardSelectionModal: () => ({
      dialogContainer: {
        overflow: 'hidden',
      },
      dialog: {
        width: '100%',
        maxWidth: '536px',
        maxHeight: '592px',
        borderRadius: '24px',
        margin: '0 24px',
      },
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
      },
    }),
    gameStudentModal: () => ({
      dialogContainer: {
        overflow: 'hidden',
      },
      dialog: {
        width: '100%',
        maxWidth: '1104px',
        maxHeight: '620px',
        borderRadius: '24px',
        margin: '0 24px',
      },
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
      },
    }),
    HUW: () => ({
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
        overflow: 'scroll',
      },
      dialog: {
        position: 'fixed',
        margin: '0',
        borderRadius: '16px',
        bg: 'white',
        width: 'auto',
        maxWidth: '280px',
        minWidth: '150px',
        boxShadow: '0px 0px 16px 0px #D0D2D84D',
      },
      overlay: {
        bg: 'rgba(0, 0, 0, 0)',
      },
    }),
    huwModal: () => ({
      dialogContainer: {
        position: 'absolute',
        paddingBottom: '30px',
        overflow: 'hidden',
      },
      overlay: {
        bg: 'rgba(0, 0, 0, 0)',
      },
      dialog: {
        height: 'fit-content',
        borderRadius: '16px',
        margin: '0 24px',
        bg: 'white',
        width: 'auto',
        maxWidth: '350px',
        minWidth: '150px',
        boxShadow: `0px 0px 16px 0px #D0D2D84D`,
        position: 'absolute',
        marginBottom: '30px !important',
        '@media (max-width: 769px)': {
          margin: '0 16px',
          maxWidth: `calc(100% - ${custom.rightSidebarWidth.mobile})`,
        },
      },
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
        overflow: 'scroll',
      },
    }),
    huwModalExamPrep: () => ({
      dialogContainer: {
        position: 'absolute',
        paddingBottom: '30px',
        overflow: 'hidden',
      },
      overlay: {
        bg: 'rgba(0, 0, 0, 0)',
      },
      dialog: {
        height: 'fit-content',
        borderRadius: '16px',
        margin: '0 24px',
        bg: 'white',
        width: 'auto',
        maxWidth: '350px',
        minWidth: '300px',
        boxShadow: `0px 0px 16px 0px #D0D2D84D`,
        position: 'absolute',
        marginBottom: '30px !important',
        '@media (max-width: 769px)': {
          margin: '0 16px',
          maxWidth: `calc(100% - ${custom.rightSidebarWidth.mobile})`,
        },
      },
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
        overflow: 'scroll',
      },
    }),
    pricingPLans: () => ({
      dialogContainer: {},
      dialog: {
        maxWidth: '648px',
        height: '600px',
        borderRadius: '24px',
        bg: `${colors.brand.gray25}`,
      },
      header: {
        padding: `${custom.space.xl} ${custom.space.xxxl}`,
      },
      footer: {
        gap: `${custom.space.md} `,
      },
      body: {
        padding: `${custom.space.xxl} 56px`,
        textAlign: 'normal',
        overflowX: 'hidden',
        overflowY: 'scroll',
      },
    }),
    teacherAddAssessments: () => ({
      dialogContainer: {},
      body: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'start',
        padding: '24px',
        gap: '24px',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '440px',
        marginRight: '4px',
        '::-webkit-scrollbar': {
          backgroundColor: 'transparent',
          display: 'block',
          width: '8px',
          height: '8px',
          borderRadius: '50px',
        },

        '::-webkit-scrollbar-thumb': {
          backgroundColor: `${colors.brand.gray300}`,
          height: '8px',
          borderRadius: '50px',
        },

        '::-webkit-scrollbar-track-piece:end': {
          backgroundColor: `${colors.brand.gray50}`,
          borderRadius: '50px',
          marginBottom: '15px',
        },

        '::-webkit-scrollbar-track-piece:start': {
          backgroundColor: `${colors.brand.gray50}`,
          borderRadius: '50px',
          marginTop: '15px',
        },
      },
      dialog: {
        width: '536px',
        minWidth: '536px',
        borderRadius: '24px',
        margin: '24px',

        '@media (max-width: 768px)': {
          marginRight: '24px',
          width: 'calc(100vw - 48px)',
          minWidth: 'calc(100vw - 48px)',
          overflowY: 'auto',
        },
      },
    }),
    teacherEditUser: () => ({
      dialogContainer: {},
      body: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'start',
        padding: '24px',
        gap: '24px',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '440px',
        marginRight: '4px',
        '::-webkit-scrollbar': {
          backgroundColor: 'transparent',
          display: 'block',
          width: '8px',
          height: '8px',
          borderRadius: '50px',
        },

        '::-webkit-scrollbar-thumb': {
          backgroundColor: `${colors.brand.gray300}`,
          height: '8px',
          borderRadius: '50px',
        },

        '::-webkit-scrollbar-track-piece:end': {
          backgroundColor: `${colors.brand.gray50}`,
          borderRadius: '50px',
          marginBottom: '15px',
        },

        '::-webkit-scrollbar-track-piece:start': {
          backgroundColor: `${colors.brand.gray50}`,
          borderRadius: '50px',
          marginTop: '15px',
        },
      },
      dialog: {
        width: '536px',
        minWidth: '536px',
        borderRadius: '24px',
        maxHeight: '80vh',
        margin: '24px',
        minHeight: '470px',

        '@media (max-width: 768px)': {
          marginRight: '24px',
          width: 'calc(100vw - 48px)',
          minWidth: 'calc(100vw - 48px)',
          overflowY: 'auto',
        },
      },
    }),
    feedbackModal: () => ({
      dialogContainer: {},
      body: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'start',
        padding: '24px 12px 24px 24px',
        gap: '24px',
      },
      dialog: {
        width: '100%',
        maxWidth: '536px',
        maxHeight: '592px',
        borderRadius: '24px',
        margin: '16px',
      },
    }),
    raceModal: () => ({
      dialogContainer: {
        alignItems: 'center',
        padding: '24px 0',
        '@media (max-height: 620px)': {
          alignItems: 'flex-start',
        },
      },
      dialog: {
        width: '100%',
        maxWidth: '1104px',
        maxHeight: '620px',
        borderRadius: '24px',
        margin: '0 24px',
        '::-webkit-scrollbar': {
          backgroundColor: 'transparent',
          display: 'block',
          width: '8px',
          height: '8px',
          borderRadius: '50px',
        },

        '::-webkit-scrollbar-thumb': {
          backgroundColor: `${colors.brand.gray300}`,
          height: '8px',
          borderRadius: '50px',
        },

        '::-webkit-scrollbar-track-piece:end': {
          backgroundColor: `${colors.brand.gray50}`,
          marginLeft: '12px',
          borderRadius: '50px',
          marginBottom: '15px',
        },

        '::-webkit-scrollbar-track-piece:start': {
          backgroundColor: `${colors.brand.gray50}`,
          marginRight: '12px',
          borderRadius: '50px',
          marginTop: '15px',
        },

        '@media (max-width: 880px)': {
          overflowY: 'auto',
        },
      },
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
      },
    }),
    inviteModal: () => ({
      dialogContainer: {
        overflow: 'hidden',
      },
      dialog: {
        width: '100%',
        maxWidth: '536px',
        borderRadius: '24px',
        margin: '0 24px',
      },
      body: {
        display: 'flex',
        padding: '0',
        width: '100%',
        overflow: 'scroll',
      },
    }),
    passageModal: () => ({
      dialogContainer: {
        overflow: 'auto',
      },
      dialog: {
        width: '100%',
        maxWidth: '771px',
        maxHeight: '632px',
        borderRadius: '24px',
        overflow: 'hidden',
      },
    }),
  },
};
