import { decrypt, encrypt } from '@/modules/helpers';
import { createContext, useContext, useMemo, useReducer } from 'react';
import { z } from 'zod';
import { Action, initialState, State, UserPayloadSchema } from './types';

const getSessionData = (key: string) => {
  const encryptedData = sessionStorage.getItem('reg-data') as string;
  const decryptedData = decrypt(JSON.parse(encryptedData), key);
  return JSON.parse(decryptedData);
};

const setSessionData = (
  data: z.infer<typeof UserPayloadSchema>,
  key: string
) => {
  const encryptedData = encrypt(JSON.stringify(data), key);
  sessionStorage.setItem('reg-data', JSON.stringify(encryptedData));
};

function reducer(
  state: z.infer<typeof State>,
  action: z.infer<typeof Action>
): z.infer<typeof State> {
  switch (action.type) {
    case 'init': {
      const { key } = UserPayloadSchema.parse(action.payload);
      const userData = getSessionData(key as string);
      return { ...state, learner: userData };
    }
    case 'step-1': {
      const { username, password, key } = UserPayloadSchema.parse(
        action.payload
      );
      const newLearnerData = UserPayloadSchema.parse({
        ...state.learner,
        username,
        password,
      });
      setSessionData(newLearnerData, key as string);
      return {
        ...state,
        learner: newLearnerData,
        step: 2,
      };
    }
    case 'step-2':
      return state;
    case 'step-3': {
      const { topics, key } = UserPayloadSchema.parse(action.payload);
      const newLearnerData = UserPayloadSchema.parse({
        ...state.learner,
        topics,
      });
      setSessionData(newLearnerData, key as string);
      return {
        ...state,
        learner: newLearnerData,
        step: 3,
      };
    }
    case 'reset':
      sessionStorage.removeItem('reg-data');
      return initialState;
    default:
      return state;
  }
}

const RegistrationContext = createContext<
  | {
      state: z.infer<typeof State>;
      dispatch: React.Dispatch<z.infer<typeof Action>>;
    }
  | undefined
>(undefined);

type RegistrationProviderProps = {
  children: React.ReactNode;
};

export const RegistrationProvider = ({
  children,
}: RegistrationProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <RegistrationContext.Provider value={value}>
      {children}
    </RegistrationContext.Provider>
  );
};

export const useRegistrationContext = () => {
  const context = useContext(RegistrationContext);
  if (context === undefined) {
    throw new Error(
      'useRegistrationContext must be used within a RegistrationProvider'
    );
  }
  return context;
};
