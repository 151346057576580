import { ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '@/styles/colors';
import { custom } from '@/styles/custom';

export const PinInputFieldStyles: ComponentStyleConfig = {
  sizes: {
    large: () => ({
      fontSize: `${custom.font.xl}`,
      width: '72px',
      height: '72px',
    }),
  },

  variants: {
    primary: () => ({
      border: `2px solid ${colors.brand.gray50}`,
      bg: `${colors.brand.gray50}`,
      color: 'brand.gray800',
      fontWeight: 400,
      borderRadius: '8px',
    }),
  },

  defaultProps: {},
};
