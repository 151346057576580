import { ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '@/styles/colors';
import { custom } from '@/styles/custom';

export const TableStyles: ComponentStyleConfig = {
  baseStyle: {
    table: {
      width: '100%',
      textAlign: 'left',
      verticalAlign: 'top',
    },
    th: {
      bg: 'white',
      textTransform: 'none',
      fontWeight: 500,
    },
    td: {
      fontWeight: 500,
    },
  },
  variants: {
    primary: () => ({
      th: {
        fontSize: `${custom.font.md}`,
        lineHeight: `${custom.font.xl}`,
        padding: `0 0 0 ${custom.space.md}`,
        backgroundColor: 'transparent',
        minWidth: '100px',
      },
      td: {
        verticalAlign: 'top',
        lineHeight: `${custom.font.xl}`,
        padding: `${custom.space.md}`,
        whiteSpace: 'pre-line',
        fontSize: `${custom.font.md}`,
        bg: `${colors.brand.gray100}`,
        borderWidth: '8px 4px',
        borderColor: `${colors.brand.gray25}`,
        _first: {
          borderLeft: '0',
        },
        _last: {
          borderRight: '0',
        },
      },
    }),
    reverted: () => ({
      thead: {
        border: `0px solid ${colors.brand.white}`,
      },
      tr: {
        border: `0px solid ${colors.brand.white}`,
      },
      tbody: {
        border: `0px solid ${colors.brand.white}`,
      },

      th: {
        fontSize: `${custom.font.md}`,
        lineHeight: `${custom.font.xl}`,
        padding: `0 0 0 ${custom.space.md}`,
        bg: `${colors.brand.gray100}`,
        border: `4px solid ${colors.brand.white}`,
        minWidth: '250px',
        maxWidth: '294px',
        _first: {
          minWidth: '100px',
          maxWidth: '180px',
        },
        _last: {
          minWidth: '180px',
          maxWidth: '228px',
        },
      },
      td: {
        verticalAlign: 'top',
        lineHeight: `${custom.font.xl}`,
        padding: `${custom.space.md}`,
        whiteSpace: 'pre-line',
        fontSize: `${custom.font.md}`,
        _first: {
          borderLeft: '0',
        },
        _last: {
          borderRight: '0',
        },
      },
    }),
    zebra: () => ({
      thead: {
        border: `0px solid ${colors.brand.white}`,
      },
      tr: {
        border: `0px solid ${colors.brand.white}`,
        _odd: {
          bg: `${colors.brand.gray100}`,
        },
        _even: {
          bg: `${colors.brand.gray25}`,
        },
      },
      tbody: {
        border: `0px solid ${colors.brand.white}`,
      },
      th: {
        fontSize: `${custom.font.md}`,
        lineHeight: `${custom.font.xl}`,
        padding: `${custom.space.md}`,
        bg: `${colors.brand.white}`,
        minWidth: '100px',
      },
      td: {
        verticalAlign: 'top',
        lineHeight: `${custom.font.xl}`,
        padding: `${custom.space.md}`,
        whiteSpace: 'pre-line',
        fontSize: `${custom.font.md}`,
        borderWidth: '8px 4px',
        borderColor: `${colors.brand.white}`,
        _first: {
          borderLeft: '0',
          width: '156px',
        },
        _last: {
          borderRight: '0',
          width: '448px',
        },
      },
    }),
    targetZebra: () => ({
      thead: {
        border: `0px solid ${colors.brand.white}`,
      },
      tr: {
        border: `0px solid ${colors.brand.white}`,
        _odd: {
          bg: `${colors.brand.gray100}`,
        },
        _even: {
          bg: `${colors.brand.gray25}`,
        },
      },
      tbody: {
        border: `0px solid ${colors.brand.white}`,
      },
      th: {
        fontSize: `${custom.font.md}`,
        lineHeight: `${custom.font.xl}`,
        padding: `${custom.space.md}`,
        bg: `${colors.brand.white}`,
        minWidth: '100px',
      },
      td: {
        verticalAlign: 'top',
        lineHeight: `${custom.font.xl}`,
        padding: `${custom.space.md}`,
        whiteSpace: 'pre-line',
        fontSize: `${custom.font.md}`,
        borderWidth: '8px 4px',
        borderColor: `${colors.brand.white}`,
        _first: {
          borderLeft: '0',
          width: '156px',
        },
        _last: {
          borderRight: '0',
          width: '448px',
        },
      },
    }),
    zebraReverted: () => ({
      th: {
        fontSize: `${custom.font.md}`,
        lineHeight: `${custom.font.xl}`,
        padding: `${custom.space.md}`,
        bg: `${colors.brand.gray25}`,
        minWidth: '100px',
      },
      td: {
        verticalAlign: 'top',
        lineHeight: `${custom.font.xl}`,
        padding: `${custom.space.md}`,
        whiteSpace: 'pre-line',
        fontSize: `${custom.font.md}`,
        borderWidth: '8px 4px',
        borderColor: `${colors.brand.gray25}`,
        _first: {
          borderLeft: '0',
        },
        _last: {
          borderRight: '0',
        },
      },
      tr: {
        _odd: {
          bg: `${colors.brand.gray100}`,
        },
        _even: {
          bg: `${colors.brand.gray25}`,
        },
      },
    }),
    schoolInfoReverted: () => ({
      thead: {
        border: `0px solid ${colors.brand.gray25}`,
      },
      tr: {
        border: `0px solid ${colors.brand.gray25}`,
      },
      tbody: {
        border: `0px solid ${colors.brand.gray25}`,
      },

      th: {
        fontSize: `${custom.font.md}`,
        lineHeight: `${custom.font.xl}`,
        padding: `0 0 0 ${custom.space.md}`,
        bg: `${colors.brand.gray100}`,
        border: `4px solid ${colors.brand.gray25}`,
        minWidth: '250px',
        maxWidth: '294px',
        _first: {
          minWidth: '100px',
          maxWidth: '180px',
        },
        _last: {
          minWidth: '180px',
          maxWidth: '228px',
        },
      },
      td: {
        verticalAlign: 'top',
        lineHeight: `${custom.font.xl}`,
        padding: `${custom.space.md}`,
        whiteSpace: 'pre-line',
        fontSize: `${custom.font.md}`,
        _first: {
          borderLeft: '0',
        },
        _last: {
          borderRight: '0',
        },
      },
    }),
    schoolInfoZebra: () => ({
      thead: {
        border: `0px solid ${colors.brand.gray25}`,
      },
      tr: {
        border: `0px solid ${colors.brand.gray25}`,
        _odd: {
          bg: `${colors.brand.gray100}`,
        },
        _even: {
          bg: `${colors.brand.gray25}`,
        },
      },
      tbody: {
        border: `0px solid ${colors.brand.gray25}`,
      },
      th: {
        fontSize: `${custom.font.md}`,
        lineHeight: `${custom.font.xl}`,
        padding: `${custom.space.md}`,
        bg: `${colors.brand.gray25}`,
        minWidth: '100px',
      },
      td: {
        verticalAlign: 'top',
        lineHeight: `${custom.font.xl}`,
        padding: `${custom.space.md}`,
        whiteSpace: 'pre-line',
        fontSize: `${custom.font.md}`,
        borderWidth: '8px 4px',
        borderColor: `${colors.brand.gray25}`,
        _first: {
          borderLeft: '0',
          width: '156px',
        },
        _last: {
          borderRight: '0',
          width: '448px',
        },
      },
    }),
    teacherStudyGroupsAddStudents: () => ({
      tr: {
        gap: '12px',
        marginBottom: '24px',
      },
      th: {
        fontFamily: 'GothamRounded',
        backgroundColor: 'transparent',
        fontSize: '20px',
        fontWeight: '900',
        margin: 0,
        padding: 0,
        border: '24px solid transparent',
        borderCollapse: 'collapse',
        span: {
          fontSize: '16px',
          fontWeight: '500',
        },

        _first: {
          borderLeft: '0',
        },
        _last: {
          borderRight: '0',
        },
      },
      td: {
        margin: 0,
        padding: 0,
        border: '24px solid transparent',
        borderCollapse: 'collapse',
        _first: {
          borderLeft: '0',
        },
        _last: {
          borderRight: '0',
        },
      },
    }),
    teacherStudyGroupsPendingStudents: () => ({
      table: {
        borderCollapse: 'separate',
        borderSpacing: '0 8px',
      },
      tbody: {
        tr: {
          backgroundColor: 'white',
        },
      },
      th: {
        fontFamily: 'GothamRounded',
        backgroundColor: 'transparent',
        fontSize: '16px',
        fontWeight: '700',
        margin: 0,
        padding: 0,
      },
      td: {
        margin: 0,
        padding: '8px 0',
        _first: {
          paddingLeft: '16px',
          borderTopLeftRadius: '16px',
          borderBottomLeftRadius: '16px',
        },
        _last: {
          paddingRight: '16px',
          borderTopRightRadius: '16px',
          borderBottomRightRadius: '16px',
          textAlign: 'end',
        },
      },
    }),
  },
  defaultProps: {
    variant: 'primary',
  },
};
