import {
  requestWithAccessToken,
  requestWithoutAccessToken,
} from '@/modules/apiRequests/userRequest';
import { showToast } from '@/modules/common';
import { logger } from '@/modules/logger';
import { HttpMethodType } from '@/types/common';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ZodError } from 'zod';
import { parseUrl } from './helpers';
import { BaseQueryOption, MutationFnParamsType } from './types';

/**
 * This method is a generic wrapper around useQuery to help with removing redundant code
 * @param url The URL to query from
 * @param queryKeys Serializable query keys to venture caching on top of
 * @param initialData The initial data to be plugged to the query on its first run/mount
 * @param schemaParser The zod schema parser to infer type from
 * @param transformFn An optional transformation function to transform queried data with
 * @returns a RQ object
 */
export const useBaseQuery = <T, Y = T>(
  url: string,
  queryKeys: Array<string>,
  initialData: T,
  schemaParser?: (response: T) => T,
  transformFn?: (data: T) => Y,
  extraOptions?: BaseQueryOption<T, Y>
) =>
  useQuery<T, Error | ZodError, Y>({
    queryKey: queryKeys,
    queryFn: async () => {
      const response = (await requestWithAccessToken(url, false, 'GET')) as T;
      if (schemaParser) {
        return schemaParser(response);
      }
      return response;
    },
    initialData,
    select: transformFn,
    onError: (error) => {
      showToast('Error', 'We had problems reading your data!', 'error');
      logger.error(error);
    },
    ...extraOptions,
  });

/**
 * This method is a generic wrapper around useQuery to help with removing redundant code
 * It is used for anonymous requests
 * @param url The URL to query from
 * @param queryKeys Serializable query keys to venture caching on top of
 * @param initialData The initial data to be plugged to the query on its first run/mount
 * @param schemaParser The zod schema parser to infer type from
 * @param transformFn An optional transformation function to transform queried data with
 * @returns a RQ object
 */
export const useBaseQueryAnonymous = <T, Y = T>(
  url: string,
  queryKeys: Array<string>,
  initialData: T,
  schemaParser?: (response: T) => T,
  transformFn?: (data: T) => Y,
  extraOptions?: BaseQueryOption<T, Y>
) =>
  useQuery<T, Error | ZodError, Y>({
    queryKey: queryKeys,
    queryFn: async () => {
      const response = (await requestWithoutAccessToken(
        url,
        false,
        'GET'
      )) as T;
      if (schemaParser) {
        return schemaParser(response);
      }
      return response;
    },
    initialData,
    select: transformFn,
    onError: (error) => {
      showToast('Error', 'We had problems reading your data!', 'error');
      logger.error(error);
    },
    ...extraOptions,
  });

/**
 * This method is a generic wrapper around useMutation to help with removing redundant code
 * @param url The URL to mutate from
 * @param mutationKeys Serializable mutation keys to venture caching on top of
 * @param schemaParser The zod schema parser to infer type from
 * @param httpMethod The HTTP Verbiage for the API call
 * @returns
 */
export const useBaseMutation = <T>(
  url: string | ((...args: Array<string>) => string),
  mutationKeys?: Array<string>,
  schemaParser?: (response: T) => T,
  httpMethod: HttpMethodType = 'POST'
) =>
  useMutation<T, Error | ZodError, MutationFnParamsType, unknown>({
    mutationFn: async ({ urlPath, body }: MutationFnParamsType) => {
      const urlParsed = parseUrl(url, urlPath);
      const response = (await requestWithAccessToken(
        urlParsed,
        body,
        httpMethod
      )) as T;
      if (schemaParser) return schemaParser(response);
      return response;
    },
    ...(mutationKeys?.length !== 0 && { mutationKey: mutationKeys }),
    onError: (error) => {
      showToast('Error', 'There was an error, please try again.', 'error');
      logger.error(error);
    },
  });
