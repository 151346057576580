import { Button } from '@chakra-ui/react';
import styled from 'styled-components';

export const ButtonWrapper = styled(Button).attrs((props) => ({
  ...(props.$w === 'auto'
    ? {}
    : {
        width: props.$w === 'default' ? `100%` : `${props.$w}px`,
      }),
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.space.md};
`;
