import { useMutation } from '@tanstack/react-query';
import { requestWithAccessToken } from '@/modules/apiRequests/userRequest';
import { logger } from '@/modules/logger';
import { COMPLETE_FTUE_STEP_URL, USERS_URL } from '../paths';

export const useUpdateOnboardingMutation = (onSuccess?: () => void) =>
  useMutation({
    mutationFn: async () => {
      const response = await requestWithAccessToken(
        USERS_URL,
        { isOnboarding: false },
        'PATCH'
      );
      if (response.errorMessage) {
        throw Error(response.errorMessage);
      }
      return response;
    },
    onSuccess,
    onError: (error) => {
      logger.error(error);
    },
  });

export const useCompleteFTUEStepMutation = (onSuccess?: (data: any) => void) =>
  useMutation({
    mutationFn: async (step: number) => {
      const response = await requestWithAccessToken(
        COMPLETE_FTUE_STEP_URL,
        { step },
        'PATCH'
      );
      if (response.errorMessage) {
        throw Error(response.errorMessage);
      }
      return response;
    },
    onSuccess,
    onError: (error) => {
      logger.error(error);
    },
  });
