// globalStyles.ts

export const globalStyles = {
  'html,body': {
    lineHeight: 'normal',
    fontFamily: 'GothamRounded',
    height: '100%',
    backgroundColor: 'rgb(255, 255, 255)',

    overscrollBehaviorX: 'none',
  },

  '#__next': {
    height: '100%',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(5px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateY(5px)',
    },
  },
  '& .select__menu': {
    animation: 'fadeIn 0.25s ease-in-out !important',
  },
  '& .menu--close': {
    animation: 'fadeOut 0.25s ease-in-out !important',
  },
};
