import { z } from 'zod';

export const ExamSubjectDtoSchema = z.object({
  code: z.string(),
  name: z.string(),
  order: z.number(),
  subjectType: z.string().optional(),
  subjectTypeName: z.string().optional(),
});

const ExamConfigSubjectSchema = ExamSubjectDtoSchema.extend({
  display: z.string(),
});

export const ExamConfigSchema = z.object({
  code: z.string(),
  name: z.string(),
  subjects: z.optional(z.array(ExamConfigSubjectSchema)),
});
