/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useMemo, useReducer } from 'react';

import { TeacherModuleBuilderType } from '../../types/common';
import { TeacherModuleBuilderAction } from './actions';

export const teacherModuleBuilderInitialValues: TeacherModuleBuilderType = {
  examType: {
    value: '',
    label: '',
  },
  passages: [],
  interests: [],
  students: [],
  events: [],
  studentIds: [],
  years: [],
  timeOfAssessment: undefined,
  numberOfExamQuestions: undefined,
  modeType: undefined,
  examMode: 'STUDY',
  generateHints: false,
  eventsTotalLength: 0,
  isPrebuilt: false,
  maxTimeValue: 60,
};

function reducer(
  state: TeacherModuleBuilderType,
  action: TeacherModuleBuilderAction
): TeacherModuleBuilderType {
  switch (action.type) {
    case 'initialStep':
      return {
        ...structuredClone(state),
        ...structuredClone(action.payload),
      };
    case 'examCustomStep':
      return {
        ...structuredClone(state),
        ...structuredClone(action.payload),
      };
    case 'init':
      return {
        ...structuredClone(state),
        ...structuredClone(action.payload),
      };
    case 'reset':
      return {
        ...structuredClone(teacherModuleBuilderInitialValues),
      };
    case 'editDraft': {
      return {
        ...structuredClone(teacherModuleBuilderInitialValues),
        examId: action.payload.examId,
        examStatus: 'draft',
      };
    }
    case 'newEvents': {
      return {
        ...structuredClone(state),
        events: action.payload.events,
        ...((action.payload.numberOfExamQuestions ||
          action.payload.numberOfExamQuestions === 0) && {
          numberOfExamQuestions: action.payload.numberOfExamQuestions,
        }),
        eventsTotalLength: action.payload.eventsTotalLength,
      };
    }
    case 'reviewAssignemnt': {
      return {
        ...structuredClone(teacherModuleBuilderInitialValues),
        examId: action.payload.examId,
        isComplete: true,
        examStatus: 'assignment',
      };
    }
    case 'passage': {
      return {
        ...structuredClone(state),
        events: [...action.payload.events],
        eventsTotalLength: action.payload.eventsTotalLength,
        numberOfExamQuestions: action.payload.numberOfExamQuestions,
        passages: structuredClone(action.payload.passages),
        section: action.payload.section,
        subject: action.payload.subject,
        adaptive: action.payload.adaptive,
      };
    }
    case 'deleteEvents': {
      return {
        ...structuredClone(state),
        events: [],
        eventsTotalLength: 0,
        numberOfExamQuestions: 0,
        passages: [],
        section: undefined,
        subject: undefined,
        adaptive: undefined,
        genre: undefined,
        questions: [],
        passageLength: 0,
      };
    }
    case 'minMax': {
      return {
        ...structuredClone(state),
        minLength: action.payload.minLength,
        maxLength: action.payload.maxLength,
      };
    }
    case 'libraryExam': {
      return {
        ...structuredClone(state),
        examId: action.payload.examId,
        isComplete: true,
        examStatus: 'library',
        isPrebuilt: true,
      };
    }
    case 'prebuilt': {
      let max = 60;
      if ((action.payload.timeOfAssessment ?? 0) > 60) {
        max = Math.ceil(action.payload.timeOfAssessment! / 10) * 10;
      }
      return {
        ...structuredClone(teacherModuleBuilderInitialValues),
        ...structuredClone(state),
        ...structuredClone(action.payload),
        maxTimeValue: max,
      };
    }
    case 'adaptivePrebuilt': {
      let max = 60;
      if ((action.payload.timeOfAssessment ?? 0) > 60) {
        max = Math.ceil(action.payload.timeOfAssessment! / 10) * 10;
      }
      return {
        ...structuredClone(teacherModuleBuilderInitialValues),
        ...structuredClone(state),
        ...structuredClone(action.payload),
        maxTimeValue: max,
      };
    }
    case 'assignment': {
      return {
        ...structuredClone(state),
        assignmentMode: action.payload.assignmentMode,
      };
    }
    case 'updateName': {
      return {
        ...structuredClone(state),
        examName: action.payload,
      };
    }
    case 'totalGeneratedEvents': {
      return {
        ...structuredClone(state),
        totalGeneratedEvents: action.payload,
      };
    }

    default: {
      console.error(
        `ERROR (Teacher Module Builder Resolver): Invalid Action Type: 
TYPE: ${(action as any).type}
PAYLOAD: ${(action as any).payload}`
      );
      return state;
    }
  }
}

export const TeacherModuleBuilerContext = createContext<
  | {
      state: TeacherModuleBuilderType;
      dispatch: React.Dispatch<TeacherModuleBuilderAction>;
    }
  | undefined
>(undefined);

export const useTeacherModuleBuilderContext = () => {
  const context = useContext(TeacherModuleBuilerContext);
  if (context === undefined) {
    throw new Error(
      'useTeacherModuleBuilderContext must be used within a RegistrationProvider'
    );
  }
  return context;
};

type TeacherModuleBuilderContextProviderProps = {
  children: React.ReactNode;
};

export const TeacherModuleBuilderContextProvider = ({
  children,
}: TeacherModuleBuilderContextProviderProps) => {
  const [state, dispatch] = useReducer(
    reducer,
    teacherModuleBuilderInitialValues
  );

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <TeacherModuleBuilerContext.Provider value={value}>
      {children}
    </TeacherModuleBuilerContext.Provider>
  );
};
