import {
  GroupValue,
  SubjectByStudyGroup,
  SubjectValue,
} from '@/components/teacher/Dashboard/DashboardTeacher/types';
import { StudentCombinedType } from '@/components/teacher/Dashboard/DiagnosticTab/types';
import React, { createContext, useContext, useMemo, useState } from 'react';

type SchoolAdminContextProps = {
  children: React.ReactNode;
};
type TeacherInfo = {
  teacherAssistantId: string;
  teacherAssistantName: string;
};
type SchoolAdminState = {
  plotDataSelection1: any;
  plotDataSelection2: any;
  selectionType: string;
  activeStudentId?: string;
  activeTeacherId?: string;
  groupValue: GroupValue | null;
  subjectValue: SubjectValue | null;
  localUser: StudentCombinedType | null;
  currentSubjectsByStudyGroups: SubjectByStudyGroup[] | null;
  paginatedTeachers?: TeacherInfo[];
};

type UpdateSchoolAdminStateAction = {
  type: 'UPDATE_SCHOOL_ADMIN_DIAGNOSTIC_STATE';
  payload: {
    plotDataSelection1?: any;
    plotDataSelection2?: any;
    selectionType: string;
  };
};
type RemoveSchoolAdminStateAction = {
  type: 'REMOVE_SCHOOL_ADMIN_DIAGNOSTIC_STATE';
  payload: {
    plotDataSelection: 'plotDataSelection1' | 'plotDataSelection2';
  };
};

type UpdateSelectionTypeAction = {
  type: 'UPDATE_SCHOOL_ADMIN_SELECTION_TYPE';
  payload: {
    selectionType: string;
  };
};

type UpdateActiveStudentIdAction = {
  type: 'UPDATE_SCHOOL_ADMIN_ACTIVE_STUDENT_ID';
  payload: {
    activeStudentId: string;
  };
};

type UpdateActiveTeacherIdAction = {
  type: 'UPDATE_SCHOOL_ADMIN_ACTIVE_TEACHER_ID';
  payload: {
    activeTeacherId: string;
  };
};

type UpdateGroupValueAction = {
  type: 'UPDATE_SCHOOL_ADMIN_GROUP_VALUE';
  payload: {
    groupValue: GroupValue | null;
  };
};

type UpdateSubjectValueAction = {
  type: 'UPDATE_SCHOOL_ADMIN_SUBJECT_VALUE';
  payload: {
    subjectValue: SubjectValue | null;
  };
};

type UpdateLocalUserAction = {
  type: 'UPDATE_SCHOOL_ADMIN_LOCAL_USER';
  payload: {
    localUser: StudentCombinedType | null;
  };
};
type UpdateCurrentSubjectsByStudyGroupsAction = {
  type: 'UPDATE_SCHOOL_ADMIN_CURRENT_SUBJECTS_BY_STUDY_GROUPS';
  payload: {
    currentSubjectsByStudyGroups: SubjectByStudyGroup[] | null;
  };
};
type UpdatePaginatedTeachersAction = {
  type: 'UPDATE_SCHOOL_ADMIN_PAGINATED_TEACHERS';
  payload: {
    paginatedTeachers: TeacherInfo[];
  };
};

type SchoolAdminAction =
  | UpdateSchoolAdminStateAction
  | RemoveSchoolAdminStateAction
  | UpdateSelectionTypeAction
  | UpdateActiveStudentIdAction
  | UpdateActiveTeacherIdAction
  | UpdateGroupValueAction
  | UpdateSubjectValueAction
  | UpdateLocalUserAction
  | UpdatePaginatedTeachersAction
  | UpdateCurrentSubjectsByStudyGroupsAction;

type SchoolAdminContextType = {
  state: SchoolAdminState;
  dispatch: (action: SchoolAdminAction) => void;
};

const initialSchoolAdminState: SchoolAdminState = {
  plotDataSelection1: null,
  plotDataSelection2: null,
  selectionType: '',
  activeStudentId: '',
  activeTeacherId: '',
  groupValue: null,
  subjectValue: null,
  localUser: null,
  currentSubjectsByStudyGroups: null,
  paginatedTeachers: [],
};

const schoolAdminReducer = (
  state: SchoolAdminState,
  action: SchoolAdminAction
): SchoolAdminState => {
  switch (action.type) {
    case 'UPDATE_SCHOOL_ADMIN_DIAGNOSTIC_STATE':
      return {
        ...state,
        plotDataSelection1:
          action.payload.plotDataSelection1 ?? state.plotDataSelection1,
        plotDataSelection2:
          action.payload.plotDataSelection2 ?? state.plotDataSelection2,
      };
    case 'REMOVE_SCHOOL_ADMIN_DIAGNOSTIC_STATE':
      return {
        ...state,
        [action.payload.plotDataSelection]: null,
      };
    case 'UPDATE_SCHOOL_ADMIN_SELECTION_TYPE':
      return {
        ...state,
        selectionType: action.payload.selectionType,
      };
    case 'UPDATE_SCHOOL_ADMIN_ACTIVE_STUDENT_ID':
      return {
        ...state,
        activeStudentId: action.payload.activeStudentId,
      };
    case 'UPDATE_SCHOOL_ADMIN_ACTIVE_TEACHER_ID':
      return {
        ...state,
        activeTeacherId: action.payload.activeTeacherId,
      };
    case 'UPDATE_SCHOOL_ADMIN_GROUP_VALUE':
      return {
        ...state,
        groupValue: action.payload.groupValue,
      };

    case 'UPDATE_SCHOOL_ADMIN_SUBJECT_VALUE':
      return {
        ...state,
        subjectValue: action.payload.subjectValue,
      };

    case 'UPDATE_SCHOOL_ADMIN_LOCAL_USER':
      return {
        ...state,
        localUser: action.payload.localUser,
      };

    case 'UPDATE_SCHOOL_ADMIN_CURRENT_SUBJECTS_BY_STUDY_GROUPS':
      return {
        ...state,
        currentSubjectsByStudyGroups:
          action.payload.currentSubjectsByStudyGroups,
      };
    case 'UPDATE_SCHOOL_ADMIN_PAGINATED_TEACHERS':
      return {
        ...state,
        paginatedTeachers: action.payload.paginatedTeachers,
      };
    default:
      return state;
  }
};

export const SchoolAdminContext = createContext<SchoolAdminContextType>({
  state: initialSchoolAdminState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

const SchoolAdminProvider = ({ children }: SchoolAdminContextProps) => {
  const [state, setState] = useState<SchoolAdminState>(initialSchoolAdminState);

  const contextValue = useMemo(
    () => ({
      state,
      dispatch: (action: SchoolAdminAction) => {
        setState((prevState) => schoolAdminReducer(prevState, action));
      },
    }),
    [state]
  );

  return (
    <SchoolAdminContext.Provider value={contextValue}>
      {children}
    </SchoolAdminContext.Provider>
  );
};

export const useSchoolAdminContext = () => useContext(SchoolAdminContext);

export { SchoolAdminProvider };
