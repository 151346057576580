import pino from 'pino';
import { getEnvVars } from './common';
// Create a logger instance
const { LOG_LEVEL } = getEnvVars();

export const logger = pino({
  level: LOG_LEVEL || 'error',
  stream: process.stdout,
  errorLikeObjectKeys: ['err'],
});
