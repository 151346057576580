import { colors } from '@/styles/colors';

export const MenuStyles = {
  variants: {
    teacherNotificationsFilter: () => ({
      list: {
        width: '334px',
        borderRadius: '16px',
        border: 'none',
        boxShadow: '0px 0px 16px 0px rgba(208, 210, 216, 0.30)',
      },
      divider: {
        color: `${colors.brand.gray100}`,
        width: 'calc(100% - 32px)',
        margin: '4px 16px',
      },
    }),
    dropdownMenu: () => ({
      item: {
        fontSize: '14px',
        fontWeight: 600,
        color: 'brand.gray900',
        padding: '8px 16px',
        _hover: {
          bg: 'brand.primary25',
          borderColor: 'brand.primary500',

          '& .chakra-checkbox__control': {
            bg: 'brand.primary25',
            color: 'brand.primary500',
            borderColor: 'brand.primary500',
          },
        },
        _focus: {
          boxShadow: 'none',
          bg: 'brand.primary25',
          borderColor: 'brand.primary500',

          '& .chakra-checkbox__control': {
            bg: 'brand.primary25',
            color: 'brand.primary500',
            borderColor: 'brand.primary500',
          },
        },
      },
      list: {
        padding: '8px 0px',
        minWidth: '240px',
        borderRadius: '16px',
        border: 'none',
        overflow: 'hidden',
        boxShadow: '0px 0px 16px 0px #D0D2D84D',
      },
      divider: {
        color: `${colors.brand.gray200}`,
        width: 'auto',
        margin: '8px 16px',
      },
    }),
    assign: () => ({
      list: {
        padding: '16px',
        maxWidth: '400px',
        width: '100%',
        minWidth: '350px',
        overflow: 'hidden',
        border: '2px solid',
        borderRadius: '16px',
        boxShadow: '0px 0px 24px 0px #D0D2D84D',
        borderColor: `${colors.brand.gray25}`,
      },
    }),
  },
};
