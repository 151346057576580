export type ColorMapping = {
  EXAM: string;
  CLASSWORK: string;
  HOMEWORK: string;
  'EXAM-SYLLABUS'?: string;
  READING: string;
  QUIZ: string;
};

export const colorMapping: ColorMapping = {
  EXAM: '#EF6A01',
  CLASSWORK: '#0A47A3',
  HOMEWORK: '#009EAE',
  'EXAM-SYLLABUS': '#1A816E',
  READING: '#1A816E',
  QUIZ: '#E154A7',
};

export const bgColorMapping: ColorMapping = {
  EXAM: '#FCE1CC',
  CLASSWORK: '#DAE8FD',
  HOMEWORK: '#E0F8FB',
  'EXAM-SYLLABUS': '#CCE3DF',
  READING: '#E6F1EF',
  QUIZ: '#FCEDF6',
};
