import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.space.xl};
  justify-content: center;
  align-items: center;
  img {
    animation: ${rotate} 1s linear infinite;
    -webkit-animation: ${rotate} 1s linear infinite;
    -moz-animation: ${rotate} 1s linear infinite;
    -ms-animation: ${rotate} 1s linear infinite;
    -o-animation: ${rotate} 1s linear infinite;
  }
`;

export const LoadingText = styled.p`
  font-size: ${(props) => props.theme.font.lg};
  font-weight: 900;
`;
