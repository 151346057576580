// theme-components.ts

import { AccordionStyles as Accordion } from './components/AccordionStyles';
import { AlertDialogStyles as Modal } from './components/AlertDialogStyles';
import { ButtonStyles as Button } from './components/ButtonStyles';
import { CheckBoxStyles as Checkbox } from './components/CheckBoxStyles';
import { DividerStyles as Divider } from './components/DividerStyles';
import { DrawerStyles as Drawer } from './components/DrawerStyles';
import { InputStyles as Input } from './components/InputStyles';
import { MenuStyles as Menu } from './components/MenuStyles';
import { PinInputFieldStyles as PinInput } from './components/PinInputFieldStyles';
import { RadioStyles as Radio } from './components/RadioStyles';
import { SelectStyles as Select } from './components/SelectStyles';
import { SwitchStyles as Switch } from './components/SwitchStyles';
import { TableStyles as Table } from './components/TableStyles';
import { TabsStyles as Tabs } from './components/TabsStyles';
import { TagStyles as Tag } from './components/TagStyles';
import { ToolTipStyles as Tooltip } from './components/ToolTipStyles';
import { TextareaStyles as Textarea } from './components/TextareaStyles';
import { PopoverStyles as Popover } from './components/PopoverStyles';
import { ProgressStyles as Progress } from './components/ProgressStyles';

export const themeComponents = {
  Accordion,
  Button,
  Divider,
  Checkbox,
  Radio,
  Select,
  Input,
  Modal,
  Switch,
  Tooltip,
  Tabs,
  Tag,
  Table,
  PinInput,
  Drawer,
  Menu,
  Popover,
  Progress,
  Textarea,
};
