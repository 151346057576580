import React from 'react';

import Icon from '../Icon/Icon';
import { LoadingText, SpinnerWrapper } from './Loader.styles';

type LoaderProps = {
  displayMessage?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const Loader = ({ displayMessage, size = 'xl' }: LoaderProps) => {
  return (
    <SpinnerWrapper>
      {displayMessage && <LoadingText>{displayMessage}</LoadingText>}
      <Icon type="spinner" size={size} source="local" />
    </SpinnerWrapper>
  );
};

export default Loader;
