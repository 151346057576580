import Loader from '../Loader/Loader';
import { ButtonWrapper } from './Btn.styles';

export type BtnVariantsType =
  | 'primary'
  | 'primaryLanding'
  | 'primaryOutlined'
  | 'primaryReversed'
  | 'primaryTrial'
  | 'primaryReversedLanding'
  | 'secondary'
  | 'secondaryOutlined'
  | 'ssoPrimary'
  | 'danger'
  | 'dangerOutlined'
  | 'dangerReversed'
  | 'google'
  | 'apple'
  | 'primaryIcon'
  | 'primaryEdit'
  | 'purple'
  | 'link'
  | 'linkSecondary';

type BtnWidthSize = 'default' | 'auto' | number;

type BtnSizesType = 'xs' | 'sm' | 'md' | 'lg';

type BtnPropsType = {
  children?: string | React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  size?: BtnSizesType;
  w?: BtnWidthSize;
  variant?: BtnVariantsType;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  formId?: string;
  ref?: React.RefObject<HTMLButtonElement>;
  isDisabled?: boolean;
  isLoading?: boolean;
  loaderSize?: 'sm' | 'md' | 'lg';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const Btn = ({
  children,
  type,
  size = 'md',
  w = 'default',
  variant,
  leftIcon,
  rightIcon,
  formId,
  ref,
  isDisabled,
  isLoading,
  loaderSize = 'md',
  onClick,
}: BtnPropsType): JSX.Element => {
  return (
    <ButtonWrapper
      variant={variant}
      type={type}
      form={formId}
      ref={ref}
      isDisabled={isDisabled}
      size={size}
      $w={w}
      onClick={onClick}
    >
      {leftIcon && !isLoading && leftIcon}
      {isLoading ? <Loader size={loaderSize} /> : children}
      {rightIcon && !isLoading && rightIcon}
    </ButtonWrapper>
  );
};

export default Btn;
