import { extendTheme, theme as baseTheme } from '@chakra-ui/react';

import { colors } from '@/styles/colors';
import { custom } from '@/styles/custom';
import { globalStyles } from '@/styles/globalStyles';
import { themeComponents } from '@/styles/theme-components';

const theme = extendTheme({
  ...baseTheme,
  components: themeComponents,
  styles: {
    global: globalStyles,
  },
  colors,
  custom,
  enableColorModeControl: false,
});

export default theme;
