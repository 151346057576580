import jwtDecode from 'jwt-decode';
import { AccessTokenDto, UserRoleDto } from './types';

export const decodeAccessToken = (accessToken: string) =>
  jwtDecode<AccessTokenDto>(accessToken);

export type RoleGroup = 'customer' | 'learner' | 'student' | 'teacher';

export const getRoleGroup = (
  roles: UserRoleDto[] | UserRoleDto
): RoleGroup | undefined => {
  const roleArray = Array.isArray(roles) ? roles : [roles];

  if (roleArray?.some((role) => role === 'customer' || role === 'home-payer')) {
    return 'customer';
  }

  if (
    roleArray?.some((role) => role === 'learner' || role === 'home-learner')
  ) {
    return 'learner';
  }

  if (
    roleArray?.some((role) => role === 'student' || role === 'school-student')
  ) {
    return 'student';
  }

  if (
    roleArray?.some((role) =>
      [
        'teacher',
        'super-admin',
        'head-of-department',
        'head-of-year',
        'home-teacher',
        'school-teacher',
        'school-admin-plus',
        'school-head-dept',
        'school-head-year',
        'tutor',
        'staff',
      ].includes(role)
    )
  ) {
    return 'teacher';
  }

  return undefined;
};
