import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { decodeAccessToken } from './helpers';
import { AuthReducerActionsType, AuthType } from './types';

const authReducer = (auth: AuthType, action: AuthReducerActionsType) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        isAuthenticated: true,
        userId: action.payload.userId,
        role: action.payload.role,
        roles: action.payload.decodedAccessToken.roles,
        decodedAccessToken: action.payload.decodedAccessToken,
      };
    case 'LOGOUT':
      return { isAuthenticated: false };
    case 'UPDATE_DECODED_ACCESS_TOKEN':
      return { ...auth, decodedAccessToken: action.payload };
    default:
      return auth;
  }
};
const initialAuthData: AuthType = {
  isAuthenticated: false,
};

export const AuthContext = createContext<{
  auth: AuthType;
  dispatch: React.Dispatch<AuthReducerActionsType>;
} | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [auth, dispatch] = useReducer(authReducer, initialAuthData);

  const memoizedValue = useMemo(
    () => ({ auth, dispatch }),
    [auth.isAuthenticated, auth.userId, auth.decodedAccessToken?.iat, dispatch]
  );

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    if (
      accessToken !== null &&
      Boolean(accessToken) &&
      refreshToken !== null &&
      Boolean(refreshToken)
    ) {
      const decodedAccessToken = decodeAccessToken(accessToken);
      const payload = {
        userId: decodedAccessToken.sub,
        role: decodedAccessToken.roles[0],
        roles: decodedAccessToken.roles,
        decodedAccessToken,
      };
      dispatch({
        type: 'LOGIN',
        payload,
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
};
