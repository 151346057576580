import * as Sentry from '@sentry/nextjs';
import { isEmpty } from 'lodash';
import { z, ZodSchema } from 'zod';

export const parseUrl = (
  url: string | ((...args: Array<string>) => string),
  urlPath: string | Array<string> | undefined
): string => {
  if (typeof url === 'string') return url;
  if (typeof urlPath === 'string') return url(urlPath);
  if (Array.isArray(urlPath)) return url(...urlPath);
  if (typeof urlPath === 'undefined')
    console.error('ERROR: urlPath cannot be undefined', { url, urlPath });
  return '';
};

export const getUrlWithQueryStrings = (
  url: string,
  queries?: Record<string, any>
) => {
  const params = new URLSearchParams(queries ?? {});
  const parsed = params.toString();
  return isEmpty(parsed) ? url : `${url}?${parsed}`;
};

export const safeParseWithLogging = <T>(
  schema: ZodSchema<T>,
  data: unknown
): T => {
  try {
    return schema.parse(data);
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error('ZodError:', error.errors);
      Sentry.captureException(
        new Error(`ZodError: ${JSON.stringify(error.errors)}`)
      );
    } else {
      console.error('Unknown error:', error);
      Sentry.captureException(
        new Error(`Unknown error: ${JSON.stringify(error)}`)
      );
    }
    throw error;
  }
};

export const DEFAULT_PAGINATION_LIMIT = 12;
export const DEFAULT_FAV_PAGINATION_LIMIT = 3;
