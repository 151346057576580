import { z } from 'zod';
import { safeParseWithLogging } from '../helpers';
import { EXAMS_SUBJECTS_URL, EXAMS_URL } from '../paths';
import { useBaseQuery } from '../utils';
import { ExamConfigSchema, ExamSubjectDtoSchema } from './schemas';
import { ExamConfigDto, ExamSubjectDto } from './types';

export const fetchExams = <T = Array<ExamConfigDto>>(
  enabled?: boolean,
  transformFn?: (data: Array<ExamConfigDto>) => T
) =>
  useBaseQuery<Array<ExamConfigDto>, T>(
    EXAMS_URL,
    ['exams'],
    new Array<ExamConfigDto>(),
    (response) => safeParseWithLogging(z.array(ExamConfigSchema), response),
    transformFn,
    {
      enabled,
      // TODO: uncomment this when we have a way to invalidate the cache, or another way to cache them
      // staleTime: 1000 * 60 * 60 * 3,
      // refetchInterval: 1000 * 60 * 60 * 2.5,
    }
  );

export const fetchExamSubjects = <T = Array<ExamSubjectDto>>(
  code: string,
  transformFn?: (data: Array<ExamSubjectDto>) => T
) =>
  useBaseQuery<Array<ExamSubjectDto>, T>(
    EXAMS_SUBJECTS_URL(code),
    ['exams', code, 'subjects'],
    new Array<ExamSubjectDto>(),
    (response) => safeParseWithLogging(z.array(ExamSubjectDtoSchema), response),
    transformFn,
    {
      enabled: Boolean(code),
    }
  );
