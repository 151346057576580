import { ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '@/styles/colors';

export const TextareaStyles: ComponentStyleConfig = {
  variants: {
    secondary: () => ({
      bg: `${colors.brand.white}`,
      color: `${colors.brand.gray900}`,
      borderRadius: '16px',
      border: `1px solid ${colors.brand.gray100}`,
      borderWidth: '1px',
      _focus: {
        border: `1px solid ${colors.brand.gray300}`,
      },
    }),
  },
};
