// eslint-disable-next-line camelcase
import Router from 'next/router';

import { getEnvVars, showToast } from '@/modules/common';
import { HttpMethodType } from '@/types/common';

import { decodeAccessToken } from '@/contexts/AuthContext/helpers';

const TOKEN_VALIDITY_BUFFER = 10;

const isTokenValid = (accessToken: string) => {
  const currentDate = new Date();

  currentDate.setSeconds(currentDate.getSeconds() + TOKEN_VALIDITY_BUFFER);

  const decodedAccessToken = decodeAccessToken(accessToken || '');
  const expirationDate = new Date(decodedAccessToken.exp * 1000);

  return expirationDate > currentDate;
};

export const request = async (
  path: string,
  bearer: string,
  params: object | boolean = false,
  methodType: HttpMethodType = 'GET'
) => {
  const { API_URL } = getEnvVars();

  try {
    const response = await fetch(`${API_URL}/${path}`, {
      method: methodType,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...(bearer && { Authorization: `Bearer ${bearer}` }),
      },
      ...(params && { body: JSON.stringify(params) }),
    });

    return response;
  } catch (error) {
    console.error('Error in fetch request:', error);
    throw error; // rethrow the error for the calling code to handle
  }
};

// THIS NEEDS A REFACTOR
export const requestWithAccessToken = async (
  path: string,
  params: object | boolean = false,
  methodType: HttpMethodType = 'GET'
): Promise<any> => {
  let result;

  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  // if user exists either in LS or SS
  if (accessToken && refreshToken) {
    if (isTokenValid(accessToken)) {
      const response = await request(path, accessToken, params, methodType);

      if (response.ok) {
        try {
          const responseJson = await response.json();
          result = responseJson;
        } catch (error) {
          result = response;
        }
      } else if (response.status === 401) {
        Router.push('/login');
      } else {
        try {
          result = await response.json();
        } catch (error) {
          result = response;
        }
      }
    } else {
      // need to check if the refresh token is valid if not stop the request and redirect to login
      if (!isTokenValid(refreshToken)) {
        Router.push('/login');
        showToast('Your session has expired', 'Please login again', 'info');
        throw new Error('Refresh token is not valid');
      }

      const response = await request(
        'auth/refresh',
        refreshToken,
        false,
        'GET'
      );

      if (response.ok) {
        const responseJson = await response.json();
        localStorage.setItem('accessToken', responseJson.accessToken);
        localStorage.setItem('refreshToken', responseJson.refreshToken);
        result = await requestWithAccessToken(path, params, methodType);
      } else {
        result = response;
      }
    }
  } else {
    showToast('You have no access to this page', 'Please login first', 'info');
    Router.push('/login');
  }

  return result;
};

export const requestWithoutAccessToken = async (
  path: string,
  params: object | boolean = false,
  methodType: 'POST' | 'GET' = 'GET'
): Promise<any> => {
  let result;
  const response = await request(path, '', params, methodType);

  try {
    const responseData = await response.json();
    result = responseData;
  } catch (error) {
    result = response;
  }

  return result;
};
