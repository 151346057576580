import {
  GroupValue,
  SubjectByStudyGroup,
  SubjectValue,
} from '@/components/teacher/Dashboard/DashboardTeacher/types';
import { StudentCombinedType } from '@/components/teacher/Dashboard/DiagnosticTab/types';
import React, { createContext, useContext, useMemo, useState } from 'react';

type DiagnosticContextProps = {
  children: React.ReactNode;
};

export type AssignmentStatus = 'CURRENT' | 'DONE';

export type AssignmentType = 'HOMEWORK' | 'CLASSWORK' | 'EXAM' | 'READING';

type DiagnosticState = {
  plotDataSelection1: any;
  plotDataSelection2: any;
  selectionType: string;
  activeStudentId?: string;
  groupValue: GroupValue | null;
  subjectValue: SubjectValue | null;
  assignmentStatus: AssignmentStatus[] | null;
  assignmentType: AssignmentType[] | null;
  localUser: StudentCombinedType | null;
  currentSubjectsByStudyGroups: SubjectByStudyGroup[] | null;
};

type UpdateDiagnosticStateAction = {
  type: 'UPDATE_DIAGNOSTIC_STATE';
  payload: {
    plotDataSelection1?: any;
    plotDataSelection2?: any;
    selectionType: string;
  };
};
type RemoveDiagnosticStateAction = {
  type: 'REMOVE_DIAGNOSTIC_STATE';
  payload: {
    plotDataSelection: 'plotDataSelection1' | 'plotDataSelection2';
  };
};

type UpdateSelectionTypeAction = {
  type: 'UPDATE_SELECTION_TYPE';
  payload: {
    selectionType: string;
  };
};

type UpdateActiveStudentIdAction = {
  type: 'UPDATE_ACTIVE_STUDENT_ID';
  payload: {
    activeStudentId: string;
  };
};

type UpdateGroupValueAction = {
  type: 'UPDATE_GROUP_VALUE';
  payload: {
    groupValue: GroupValue | null;
  };
};

type UpdateSubjectValueAction = {
  type: 'UPDATE_SUBJECT_VALUE';
  payload: {
    subjectValue: SubjectValue | null;
  };
};

type UpdateAssignmentTypeAction = {
  type: 'UPDATE_ASSIGNMENT_TYPE';
  payload: {
    assignmentType: AssignmentType[] | null;
  };
};

type UpdateAssignmentStatusAction = {
  type: 'UPDATE_ASSIGNMENT_STATUS';
  payload: {
    assignmentStatus: AssignmentStatus[] | null;
  };
};

type UpdateLocalUserAction = {
  type: 'UPDATE_LOCAL_USER';
  payload: {
    localUser: StudentCombinedType | null;
  };
};
type UpdateCurrentSubjectsByStudyGroupsAction = {
  type: 'UPDATE_CURRENT_SUBJECTS_BY_STUDY_GROUPS';
  payload: {
    currentSubjectsByStudyGroups: SubjectByStudyGroup[] | null;
  };
};

type ResetAction = {
  type: 'RESET';
};

type DiagnosticAction =
  | UpdateDiagnosticStateAction
  | RemoveDiagnosticStateAction
  | UpdateSelectionTypeAction
  | UpdateActiveStudentIdAction
  | UpdateGroupValueAction
  | UpdateSubjectValueAction
  | UpdateAssignmentTypeAction
  | UpdateAssignmentStatusAction
  | UpdateLocalUserAction
  | UpdateCurrentSubjectsByStudyGroupsAction
  | ResetAction;

type DiagnosticContextType = {
  state: DiagnosticState;
  dispatch: (action: DiagnosticAction) => void;
};

const initialDiagnosticState: DiagnosticState = {
  plotDataSelection1: null,
  plotDataSelection2: null,
  selectionType: '',
  activeStudentId: '',
  groupValue: null,
  subjectValue: null,
  assignmentStatus: null,
  assignmentType: null,
  localUser: null,
  currentSubjectsByStudyGroups: null,
};

const diagnosticReducer = (
  state: DiagnosticState,
  action: DiagnosticAction
): DiagnosticState => {
  switch (action.type) {
    case 'UPDATE_DIAGNOSTIC_STATE':
      return {
        ...state,
        plotDataSelection1:
          action.payload.plotDataSelection1 ?? state.plotDataSelection1,
        plotDataSelection2:
          action.payload.plotDataSelection2 ?? state.plotDataSelection2,
      };
    case 'REMOVE_DIAGNOSTIC_STATE':
      return {
        ...state,
        [action.payload.plotDataSelection]: null,
      };
    case 'UPDATE_SELECTION_TYPE':
      return {
        ...state,
        selectionType: action.payload.selectionType,
      };
    case 'UPDATE_ACTIVE_STUDENT_ID':
      return {
        ...state,
        activeStudentId: action.payload.activeStudentId,
      };
    case 'UPDATE_GROUP_VALUE':
      return {
        ...state,
        groupValue: action.payload.groupValue,
      };

    case 'UPDATE_SUBJECT_VALUE':
      return {
        ...state,
        subjectValue: action.payload.subjectValue,
      };

    case 'UPDATE_ASSIGNMENT_TYPE':
      return {
        ...state,
        assignmentType: action.payload.assignmentType,
      };

    case 'UPDATE_ASSIGNMENT_STATUS':
      return {
        ...state,
        assignmentStatus: action.payload.assignmentStatus,
      };

    case 'UPDATE_LOCAL_USER':
      return {
        ...state,
        localUser: action.payload.localUser,
      };

    case 'UPDATE_CURRENT_SUBJECTS_BY_STUDY_GROUPS':
      return {
        ...state,
        currentSubjectsByStudyGroups:
          action.payload.currentSubjectsByStudyGroups,
      };
    case 'RESET':
      return initialDiagnosticState;
    default:
      return state;
  }
};

export const DiagnosticContext = createContext<DiagnosticContextType>({
  state: initialDiagnosticState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

const DiagnosticProvider = ({ children }: DiagnosticContextProps) => {
  const [state, setState] = useState<DiagnosticState>(initialDiagnosticState);

  const contextValue = useMemo(
    () => ({
      state,
      dispatch: (action: DiagnosticAction) => {
        setState((prevState) => diagnosticReducer(prevState, action));
      },
    }),
    [state]
  );

  return (
    <DiagnosticContext.Provider value={contextValue}>
      {children}
    </DiagnosticContext.Provider>
  );
};

export const useDiagnosticContext = () => useContext(DiagnosticContext);

export { DiagnosticProvider };
