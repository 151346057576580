import React, { createContext, useContext, useMemo, useState } from 'react';

type ParentContextType = {
  activeLearnerId: string;
  setActiveLearnerId: (type: string) => void;
  activeLearnerName: string;
  setActiveLearnerName: (type: string) => void;
  activeExam: string;
  setActiveExam: (type: string) => void;
  activeTopic: string;
  setActiveTopic: (type: string) => void;
};

const ParentContext = createContext<ParentContextType>({
  activeLearnerId: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveLearnerId: () => {},
  activeLearnerName: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveLearnerName: () => {},
  activeExam: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveExam: () => {},
  activeTopic: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveTopic: () => {},
});

type ParentProviderProps = {
  children: React.ReactNode;
};

const ParentProvider = ({ children }: ParentProviderProps) => {
  const [activeLearnerId, setActiveLearnerId] = useState('');
  const [activeLearnerName, setActiveLearnerName] = useState('');
  const [activeExam, setActiveExam] = useState('');
  const [activeTopic, setActiveTopic] = useState('');

  const contextValue = useMemo(
    () => ({
      activeLearnerId,
      setActiveLearnerId,
      activeLearnerName,
      setActiveLearnerName,
      activeExam,
      setActiveExam,
      activeTopic,
      setActiveTopic,
    }),
    [
      activeLearnerId,
      setActiveLearnerId,
      activeLearnerName,
      setActiveLearnerName,
      activeExam,
      setActiveExam,
      activeTopic,
      setActiveTopic,
    ]
  );

  return (
    <ParentContext.Provider value={contextValue}>
      {children}
    </ParentContext.Provider>
  );
};

export const useParentContext = () => useContext(ParentContext);

export { ParentProvider };
