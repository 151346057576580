import {
  IconOptionsType,
  icons,
  IconTypes,
} from '@/components/common/Icon/types';
import { loadImgFromCdn } from '@/modules/imageLoader';
import { MouseEventHandler } from 'react';

import { Wrapper } from './Icon.styles';

type IconProps = {
  type: IconTypes;
  source?: 'local' | 'remote';
  size?: keyof IconOptionsType['size'];
  color?: keyof IconOptionsType['color'];
  hoverColor?: keyof IconOptionsType['hoverColor'];
  className?: string;
  preload?: boolean;
  onClick?: MouseEventHandler<HTMLImageElement>;
};

const Icon = ({
  type,
  source = 'local',
  size = 'sm',
  className,
  color = 'black',
  preload = false,
  hoverColor,
  onClick,
}: IconProps): JSX.Element => (
  <Wrapper
    src={source === 'local' ? `/icons/${icons[type]}` : icons[type]}
    loader={() => loadImgFromCdn(icons[type], '', `${source}`)}
    placeholder={source !== 'local' ? 'blur' : undefined}
    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8UA8AAmUBcaVexNkAAAAASUVORK5CYII="
    size={size}
    className={className}
    color={color}
    $hoverColor={hoverColor}
    onClick={onClick}
    alt={type}
    priority={preload}
    unoptimized
  />
);

export default Icon;
