import { colors } from '@/styles/colors';
import { custom } from '@/styles/custom';

export const AccordionStyles = {
  variants: {
    questions: () => ({
      container: {
        border: 'none',
      },
      button: {
        minHeight: '48px',
        background: `${colors.brand.white}`,
        borderRadius: `${custom.radius.sm}`,
        marginBottom: `${custom.space.md}`,
        padding: '8px 16px',
        gap: `${custom.space.lg}`,
        justifyContent: 'space-between',
        border: '2px solid transparent',
        _hover: {
          bg: `${colors.brand.white}`,
          borderColor: `${colors.brand.blue50}`,
        },
        _disabled: {
          opacity: 1,
          cursor: 'auto',

          _hover: {
            bg: `${colors.brand.white}`,
            border: '2px solid transparent',
          },
        },
      },
      panel: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: `${custom.space.md}`,
        gap: `${custom.space.md}`,
      },
    }),

    moduleBuilder: () => ({
      container: {
        border: 'none',
      },
      panel: {
        padding: 0,
      },
      button: {
        padding: ' 0.5rem 0',
        _hover: {
          bg: `${colors.brand.white}`,
        },
      },
    }),

    faq: () => ({
      button: {
        fontSize: `${custom.font.lg}`,
        color: `${colors.brand.gray900}`,
        fontWeight: 'medium',
        padding: 0,
        flex: 1,
        textAlign: 'left',
        _hover: {
          bg: `${colors.brand.white}`,
        },

        '@media (max-width: 425px)': {
          fontSize: `${custom.font.md}`,
        },
      },
      container: {
        padding: `${custom.space.xxl} 0`,
        width: '100%',
        borderTop: `1px solid ${colors.brand.gray300}`,
        borderBottom: 'none',
      },
      panel: {
        paddingTop: `${custom.space.lg}`,
        color: `${colors.brand.gray900}`,
      },
    }),

    relatedQuestions: () => ({
      button: {
        padding: '0.75rem 0',
        justifyContent: 'space-between',

        _hover: {
          background: 'none',
        },
      },

      panel: {
        padding: 0,
      },
    }),
  },
};
