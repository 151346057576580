import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { clearLocalStorage } from '@/modules/helpers';
import {
  BackToLogin,
  Bubble,
  BubbleIcon,
  Error,
  InformativeText,
  Wrapper,
} from '@/styles/pagesStyles/errorPage.styles';
import Btn from '../Btn/Btn';
import Icon from '../Icon/Icon';

const ClientErrorPage = (): JSX.Element => {
  const router = useRouter();

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      // Go back one more step in the history
      window.history.go(-2);
    };

    // Listen for the popstate event (back/forward button)
    window.addEventListener('popstate', handlePopState);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const clearEverythingAndRedirectToLogin = async () => {
    clearLocalStorage();

    await router.push('/login');

    router.reload();
  };

  return (
    <Wrapper>
      <Bubble>
        <BubbleIcon type="bubble" />

        <Icon type="sad" size="xl" />

        <Error>Something went wrong</Error>

        <InformativeText>
          {`We faced an issue and we are looking into it. Please click
          "back to login" so you check the rest of the application until we solve
          it. Thank you for the understanding.`}
        </InformativeText>

        <BackToLogin>
          <Btn onClick={clearEverythingAndRedirectToLogin}>Back to Login</Btn>
        </BackToLogin>
      </Bubble>
    </Wrapper>
  );
};

export default ClientErrorPage;
