import { ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '@/styles/colors';

export const SwitchStyles: ComponentStyleConfig = {
  baseStyle: {
    field: {
      border: '1px solid',
      borderColor: 'brand.gray300',
    },
  },
  variants: {
    primary: () => ({
      track: {
        _checked: {
          bg: `${colors.brand.primary500}`,
        },
      },
    }),
  },
  defaultProps: {
    bg: 'green',
    size: 'md',
    variant: 'primary',
  },
};
