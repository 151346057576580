import { z } from 'zod';

export const UserPayloadSchema = z.object({
  username: z.string().optional(),
  password: z.string().optional(),
  topics: z.array(z.string()).optional(),
  selected: z.array(z.string()).optional(),
  key: z.string().optional(),
  step: z.number().optional(),
});

export const State = z.object({
  learner: UserPayloadSchema.nullable(),
  step: z.number(),
});

export const Action = z.union([
  z.object({ type: z.literal('init'), payload: UserPayloadSchema }),
  z.object({ type: z.literal('step-1'), payload: UserPayloadSchema }),
  z.object({ type: z.literal('step-2'), payload: UserPayloadSchema }),
  z.object({ type: z.literal('step-3'), payload: UserPayloadSchema }),
  z.object({ type: z.literal('reset') }),
]);

export const initialState = State.parse({
  learner: { username: '', password: '', topics: [], selected: [] },
  step: 1,
});
