import { useRouter } from 'next/router';

import { useAuthContext } from '@/contexts/AuthContext';
import { showToast } from '@/modules/helpers';
import { routes } from './routes';

const checkPublicRoute = (path: string) => routes.public.has(path);

const checkRoleBasedRoute = (
  roles: string[],
  path: string,
  isCompleted = true
) => {
  return roles.some((role) => {
    const roleBasedRoutes = routes.roles[role as keyof typeof routes.roles];
    return isCompleted
      ? roleBasedRoutes.registered.has(path)
      : roleBasedRoutes.unregistered.has(path);
  });
};

const ProtectedRoute: React.FC<{ children: any }> = ({ children }) => {
  const router = useRouter();
  const { auth } = useAuthContext();

  if (typeof window !== 'undefined' && router.isReady) {
    const isPublic = checkPublicRoute(router.pathname);

    if (isPublic) {
      return children;
    }
    if (auth.isAuthenticated && auth.decodedAccessToken?.roles) {
      const isCompleted = auth.decodedAccessToken?.isCompleted;

      // Check if the user has access based on any of their roles
      const hasAccess = checkRoleBasedRoute(
        auth.decodedAccessToken?.roles,
        router.pathname,
        isCompleted
      );

      if (hasAccess) {
        return children;
      }

      router.replace('/redirect');
    } else {
      router.replace('/login');
      showToast(
        'You are not authorized to access this page.',
        'Please try to login first',
        'error',
        3000
      );
    }

    return <div />;
  }
  return <div />;
};

export default ProtectedRoute;
