export const DividerStyles = {
  baseStyle: {
    opacity: '0.2',
    bg: 'brand.gray1',
    color: 'brand.primary700',
    border: '4px solid',
    height: '2px',
  },
  variants: {
    primary: {},
    danger: () => ({
      opacity: 1,
      bg: 'brand.error700',
      height: '2px',
      margin: `custom.space.xxl 0`,
    }),
    success: () => ({
      opacity: 1,
      bg: 'brand.success700',
      height: '2px',
      margin: `custom.space.xxl 0`,
    }),
    light: () => ({
      opacity: 1,
      bg: 'brand.gray100',
      height: '1px',
      margin: `custom.space.xxl 0`,
    }),
    gray: ({
      color,
      orientation,
    }: {
      color: string;
      orientation: 'vertical' | 'horizontal';
    }) => ({
      opacity: 1,
      bg: color ? `brand.${color}` : 'brand.gray100',
      height: orientation === 'vertical' ? '100%' : '1px',
      width: orientation === 'vertical' ? '1px' : '100%',
    }),
  },
  defaultProps: {
    variant: 'primary',
    opacity: '1',
    bg: `brand.gray50`,
    height: '1px',
  },
};
