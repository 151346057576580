import { ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '@/styles/colors';

export const TagStyles: ComponentStyleConfig = {
  baseStyle: {
    field: {
      border: '1px solid',
    },
  },
  variants: {
    primary: () => ({
      container: {
        bg: `${colors.brand.primary700}`,
        color: `${colors.brand.white}`,
        height: '32px',
        borderRadius: '16px',
      },
      closeButton: {
        color: `${colors.brand.white}`,
        opacity: 1,
      },
    }),
  },
  defaultProps: {
    variant: 'primary',
  },
};
