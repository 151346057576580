import React, { createContext, useContext, useMemo, useState } from 'react';

type GlobalContextType = {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  drawerType: string;
  setDrawerType: (type: string) => void;
  isLeftDrawerOpen: boolean;
  setIsLeftDrawerOpen: (isOpen: boolean) => void;
  isRightDrawerOpen: boolean;
  setIsRightDrawerOpen: (isOpen: boolean) => void;
  resetToDefault: () => void;
};

const defaultState = {
  isSidebarOpen: false,
  drawerType: '',
  isLeftDrawerOpen: false,
  isRightDrawerOpen: false,
};

const GlobalContext = createContext<GlobalContextType>({
  isSidebarOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleSidebar: () => {},
  drawerType: 'idle',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDrawerType: () => {},
  isLeftDrawerOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsLeftDrawerOpen: () => {},
  isRightDrawerOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsRightDrawerOpen: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetToDefault: () => {},
});

type GlobalProviderProps = {
  children: React.ReactNode;
};

const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [drawerType, setDrawerType] = useState('');
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
  };

  const resetToDefault = () => {
    setIsSidebarOpen(defaultState.isSidebarOpen);
    setDrawerType(defaultState.drawerType);
    setIsLeftDrawerOpen(defaultState.isLeftDrawerOpen);
    setIsRightDrawerOpen(defaultState.isRightDrawerOpen);
  };

  const contextValue = useMemo(
    () => ({
      isSidebarOpen,
      toggleSidebar,
      drawerType,
      setDrawerType,
      isLeftDrawerOpen,
      setIsLeftDrawerOpen,
      isRightDrawerOpen,
      setIsRightDrawerOpen,
      resetToDefault,
    }),
    [
      isSidebarOpen,
      drawerType,
      setDrawerType,
      isLeftDrawerOpen,
      setIsLeftDrawerOpen,
      isRightDrawerOpen,
      setIsRightDrawerOpen,
    ]
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);

export { GlobalProvider };
