import { getEnvVars } from '@/modules/common';
import { ImageLoaderProps } from 'next/image';

const { IMAGES_URL } = getEnvVars();

export default function imageLoader(
  { src, width, quality }: ImageLoaderProps,
  folderName = ''
) {
  return `${IMAGES_URL}/${src}`;
}

export const loadImgFromCdn = (
  src: string,
  folderName = '',
  source: 'local' | 'remote' = 'local'
) => {
  return source === 'local'
    ? `/icons/${src}`
    : `${IMAGES_URL}/${folderName}/${src}`;
};
