import {
  SessionGameType,
  SessionType,
  SubjectType,
} from '@/components/teacher/Dashboard/types';
import React, { createContext, useContext, useMemo, useState } from 'react';

type TeacherProviderProps = {
  children: React.ReactNode;
};

type TeacherState = {
  activeExamId: string;
  sessionIds: string[];
  isRaceStarted: boolean;
  isRaceFinished: boolean;
  isSessionStarted: boolean;
  isGamePaused: boolean;
  isRaceCanceled: boolean;
  valueSelected: SubjectType | null;
  session: SessionType | SessionGameType | null;
  sessionStudents: any[] | null;
  invitationsClosed: boolean;
  studentStateData: any;
  typeOfAssessment: string;
  typeOfRace: string;
  subjectOfRace: string;
  tempDetails: any;
};

type UpdateGameStateAction = {
  type: 'UPDATE_GAME_STATE';
  payload: {
    isSessionStarted: boolean;
    isGamePaused: boolean;
    isRaceStarted: boolean;
    valueSelected: SubjectType | null;
    invitationsClosed: boolean;
  };
};

type TeacherAction =
  | { type: 'INVITE'; payload: string }
  | {
      type: 'SET_ASSESSMENT_TYPE';
      payload: string;
    }
  | { type: 'SET_VALUE_SELECTED'; payload: SubjectType | null }
  | { type: 'SET_SESSION_STUDENTS'; payload: any[] }
  | {
      type: 'SET_ACTIVE_EXAM';
      payload: {
        activeExamId: string;
        session: SessionType | SessionGameType | null;
      };
    }
  | { type: 'SET_STUDENT_DATA'; payload: any }
  | { type: 'SET_ALL_SESSIONS'; payload: string[] }
  | UpdateGameStateAction
  | { type: 'INVITATIONS_CLOSED' }
  | { type: 'START' }
  | { type: 'PAUSE' }
  | { type: 'UNPAUSE' }
  | { type: 'CANCEL' }
  | { type: 'FINISHED' }
  | { type: 'COMPLETE' }
  | { type: 'SET_TYPE_OF_RACE'; payload: string }
  | { type: 'SET_SUBJECT_OF_RACE'; payload: string }
  | { type: 'SET_ACTIVE_EXAM_ID_PENDING'; payload: string }
  | { type: 'SET_TEMP_DETAILS'; payload: any }
  | { type: 'SET_ACTIVE_EXAM_ID'; payload: string };

type TeacherContextType = {
  state: TeacherState;
  dispatch: (action: TeacherAction) => void;
  resetState: () => void;
};

const initialTeacherState: TeacherState = {
  activeExamId: '',
  sessionIds: [],
  isRaceStarted: false,
  isRaceFinished: false,
  isSessionStarted: false,
  isGamePaused: false,
  isRaceCanceled: false,
  valueSelected: null,
  session: null,
  sessionStudents: null,
  invitationsClosed: false,
  studentStateData: [],
  typeOfAssessment: '',
  typeOfRace: '',
  subjectOfRace: '',
  tempDetails: {},
};

const teacherReducer = (
  state: TeacherState,
  action: TeacherAction
): TeacherState => {
  switch (action.type) {
    case 'INVITE':
      return {
        ...state,
        isRaceStarted: false,
        isRaceFinished: false,
        isGamePaused: false,
        activeExamId: action.payload,
        isSessionStarted: true,
        invitationsClosed: true,
      };
    case 'SET_ASSESSMENT_TYPE':
      return {
        ...state,
        typeOfAssessment: action.payload,
      };
    case 'SET_VALUE_SELECTED':
      return {
        ...state,
        isRaceStarted: false,
        isRaceFinished: false,
        isSessionStarted: false,
        isGamePaused: false,
        invitationsClosed: false,
        session: null,
        sessionStudents: null,
        valueSelected: action.payload,
      };
    case 'SET_ACTIVE_EXAM':
      return {
        ...state,
        activeExamId: action.payload.activeExamId,
        session: action.payload.session,
      };
    case 'SET_STUDENT_DATA':
      return {
        ...state,
        studentStateData: action.payload,
      };
    case 'SET_SESSION_STUDENTS':
      return {
        ...state,
        sessionStudents: action.payload,
      };
    case 'SET_ALL_SESSIONS':
      return {
        ...state,
        sessionIds: action.payload,
      };
    case 'INVITATIONS_CLOSED':
      return {
        ...state,
        invitationsClosed: true,
      };
    case 'UPDATE_GAME_STATE':
      return {
        ...state,
        isSessionStarted: action.payload.isSessionStarted,
        isGamePaused: action.payload.isGamePaused,
        isRaceStarted: action.payload.isRaceStarted,
        valueSelected: action.payload.valueSelected,
        invitationsClosed: action.payload.invitationsClosed,
      };
    case 'START':
      return {
        ...state,
        isSessionStarted: true,
        isRaceStarted: true,
        isGamePaused: false,
      };
    case 'PAUSE':
      return {
        ...state,
        isSessionStarted: true,
        isRaceStarted: true,
        isGamePaused: true,
        invitationsClosed: true,
      };
    case 'UNPAUSE':
      return { ...state, isGamePaused: false };
    case 'FINISHED':
      return { ...state, isRaceFinished: true };
    case 'CANCEL':
      return {
        ...state,
        sessionIds: [],
        isRaceCanceled: true,
        isRaceStarted: false,
        isRaceFinished: false,
        isSessionStarted: false,
        isGamePaused: false,
        invitationsClosed: false,
        session: null,
      };
    case 'COMPLETE':
      return {
        ...state,
        isRaceFinished: true,
        isRaceStarted: false,
        invitationsClosed: false,
      };

    case 'SET_TYPE_OF_RACE':
      return {
        ...state,
        typeOfRace: action.payload,
      };
    case 'SET_SUBJECT_OF_RACE':
      return {
        ...state,
        subjectOfRace: action.payload,
      };
    case 'SET_TEMP_DETAILS':
      return {
        ...state,
        tempDetails: action.payload,
      };
    case 'SET_ACTIVE_EXAM_ID':
      return {
        ...state,
        activeExamId: action.payload,
        isSessionStarted: true,
      };
    case 'SET_ACTIVE_EXAM_ID_PENDING':
      return {
        ...state,
        activeExamId: action.payload,
      };
    default:
      return state;
  }
};

export const TeacherContext = createContext<TeacherContextType>({
  state: initialTeacherState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetState: () => {},
});

const TeacherProvider = ({ children }: TeacherProviderProps) => {
  const [state, setState] = useState<TeacherState>(initialTeacherState);

  const contextValue = useMemo(
    () => ({
      state,
      dispatch: (action: TeacherAction) => {
        setState((prevState) => teacherReducer(prevState, action));
      },
      resetState: () => {
        setState(initialTeacherState);
      },
    }),
    [state]
  );

  return (
    <TeacherContext.Provider value={contextValue}>
      {children}
    </TeacherContext.Provider>
  );
};

export const useTeacherContext = () => useContext(TeacherContext);

export { TeacherProvider };
