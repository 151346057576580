export const SelectStyles = {
  baseStyle: {
    field: {
      border: '1px solid',
      borderColor: 'brand.gray300',
    },
  },
  variants: {
    annotation: {
      field: {
        border: 'none',
        borderRadius: '0px',
        paddingRight: '30px',
        fontFamily: 'GothamRounded',
        fontWeight: '700',
      },
    },

    primary: {
      field: {
        borderRadius: '24px',
      },
    },
    primaryNoBorder: {
      field: {
        border: 'none',
        borderRadius: '8px',
      },
    },
    secondary: {
      field: {
        borderRadius: '8px',
      },
    },
    country: {
      field: {
        borderRadius: '24px',
        borderRightRadius: '0px',
        height: '48px',
      },
    },
    // the variant below should be deleted when word list has data
    wordListDummy: {
      field: {
        border: 'none',
        borderRadius: '8px',
        height: '56px',
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'primary',
  },
};
