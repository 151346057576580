import { ComponentStyleConfig } from '@chakra-ui/react';

import { custom } from '@/styles/custom';

const activeTabStyle = {
  color: 'brand.gray900',
  borderBottom: '6px solid',
  borderColor: 'brand.gray900',
  boxShadow: 'none',
};

const teacherPortalTabs = (width: 'lg' | 'sm') => ({
  root: {
    height: '100%',
    width: '100%',
  },
  tablist: {
    margin: '0px auto',
    paddingTop: '0px',
    backgroundColor: 'brand.gray25',
    position: 'fixed',
    zIndex: 5,
    maxWidth: custom.layoutMaxSize.header,
    left: custom.leftSidebarWidth.desktop,
    right: custom.rightSidebarWidth.desktop,
    width: `calc(100% - ${custom.rightSidebarWidth.desktop} - ${custom.leftSidebarWidth.desktop})`,
    paddingLeft: '32px !important',
    paddingRight: '32px !important',

    '@media (max-width: 1279px)': {
      width: `calc(100% - ${custom.rightSidebarWidth.mobile} - ${custom.leftSidebarWidth.desktop})`,
    },
    '@media (max-width: 1024px)': {
      width: `calc(100% - ${custom.rightSidebarWidth.mobile}) !important`,
      left: 0,
      top: '72px',
    },
    '@media (max-width: 768px)': {
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
      width: `100% !important`,
    },
  },

  tabpanels: {
    padding: `32px 57px !important`,
    '@media (max-width: 1280px)': {
      padding: '32px !important',
    },
    '@media (max-width: 768px)': {
      padding: '16px !important',
    },
  },
  tab: {
    maxWidth: width === 'sm' ? '200px' : '270px',
    '@media (max-width: 1195px)': {
      maxWidth: `50% !important`,
    },
  },
  tabpanel: {
    marginBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    pl: '0px',
  },
});
const studentSubTabs = () => ({
  root: {
    height: '100%',
    width: '100%',
  },
  tablist: {
    margin: '0px auto',
    paddingTop: '20px !important',
    backgroundColor: 'brand.gray25',
    position: 'fixed',
    zIndex: 5,
    maxWidth: custom.layoutMaxSize.header,
    top: '121px',
    left: custom.leftSidebarWidth.desktop,
    right: custom.rightSidebarWidth.desktop,
    width: `calc(100% - ${custom.rightSidebarWidth.desktop} - ${custom.leftSidebarWidth.desktop})`,
    paddingLeft: '32px !important',
    paddingRight: '32px !important',

    '@media (max-width: 1279px)': {
      width: `calc(100% - ${custom.rightSidebarWidth.mobile} - ${custom.leftSidebarWidth.desktop})`,
    },
    '@media (max-width: 1024px)': {
      width: `calc(100% - ${custom.rightSidebarWidth.mobile}) !important`,
      left: 0,
      top: '113px',
    },
    '@media (max-width: 768px)': {
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
      width: `100% !important`,
    },
  },

  tabpanels: {
    padding: `40px 0 0 !important`,
  },
  tab: {
    maxWidth: '200px',
    '@media (max-width: 1195px)': {
      maxWidth: `50% !important`,
    },
  },
  tabpanel: {
    marginBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    pl: '0px',
  },
});

export const TabsStyles: ComponentStyleConfig = {
  baseStyle: () => ({
    root: {
      height: '100%',
      width: '100%',
    },
    tablist: {
      borderBottom: '1px solid',
      borderColor: 'brand.gray5',
      margin: '0',
      padding: '32px',
      backgroundColor: 'brand.gray25',
      '@media (max-width: 1024px)': {
        padding: `0 24px 0`,
        width: `calc(100% - ${custom.rightSidebarWidth.mobile})`,
        left: '0px',
      },
    },
    tab: {
      width: '100%',
      maxWidth: '254px',
      color: 'brand.gray700',
      fontWeight: '400',
      marginBottom: '-1px',
      _selected: {
        ...activeTabStyle,
        _hover: {
          ...activeTabStyle,
        },
      },
      _hover: {
        color: 'brand.gray900',
        borderBottom: '6px solid',
        borderColor: 'brand.gray300',
      },

      borderBottom: '6px solid',
      borderColor: 'brand.gray100',
    },
    tabpanel: {
      margin: '0px',
      padding: '32px 0px 40px 0',
      '@media (max-width: 1024px)': {
        padding: '24px 0px 32px 0',
      },
    },
  }),
  variants: {
    primary: (props) => ({
      tablist: {
        position: 'fixed',
        top: '80px',
        zIndex: 5,
        margin: '0 auto',
        maxWidth: custom.layoutMaxSize.header,
        left: custom.leftSidebarWidth.desktop,
        right: custom.rightSidebarWidth.desktop,
        width: `calc(100% - ${custom.rightSidebarWidth.desktop} - ${custom.leftSidebarWidth.desktop})`,
        '@media (max-width: 1279px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.mobile} - ${custom.leftSidebarWidth.desktop})`,
        },
        '@media (max-width: 1024px)': {
          top: '72px',
          width: `calc(100% - ${custom.rightSidebarWidth.mobile}) !important`,
          left: 0,
        },
        '@media (max-width: 768px)': {
          paddingLeft: '0 16px',
          width: `100% !important`,
        },
      },
      tabpanels: {
        padding: `32px 57px !important`,
        '@media (max-width: 1280px)': {
          padding: '32px !important',
        },
        '@media (max-width: 768px)': {
          padding: '16px !important',
        },
      },
      tab: {
        padding: '0 16px 8px 16px',
        maxWidth: props.width || '271px',
        '@media (max-width: 1195px)': {
          maxWidth: `50% !important`,
        },
      },
    }),
    classList: (props) => ({
      tablist: {
        position: 'fixed',
        top: '80px',
        zIndex: 6,
        margin: '0 auto',
        maxWidth: custom.layoutMaxSize.header,
        left: custom.leftSidebarWidth.desktop,
        right: custom.rightSidebarWidth.desktop,
        width: `calc(100% - ${custom.rightSidebarWidth.desktop} - ${custom.leftSidebarWidth.desktop})`,
        '@media (max-width: 1279px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.mobile} - ${custom.leftSidebarWidth.desktop})`,
        },
        '@media (max-width: 1024px)': {
          top: '72px',
          width: `calc(100% - ${custom.rightSidebarWidth.mobile}) !important`,
          left: 0,
        },
        '@media (max-width: 768px)': {
          paddingLeft: '0 16px',
          width: `100% !important`,
        },
      },
      tabpanels: {
        padding: `32px 57px !important`,
        '@media (max-width: 1280px)': {
          padding: '32px !important',
        },
        '@media (max-width: 768px)': {
          padding: '16px !important',
        },
      },
      tab: {
        padding: '0 16px 8px 16px',
        maxWidth: props.width || '271px',
        '@media (max-width: 1195px)': {
          maxWidth: `50% !important`,
        },
      },
    }),
    assessmentHall: () => ({
      root: {
        height: '100%',
        width: '100%',
        position: 'relative',
      },
      tablist: {
        maxWidth: custom.layoutMaxSize.header,
        left: custom.leftSidebarWidth.desktop,
        margin: '0 auto',
        right: custom.rightSidebarWidth.desktop,
        padding: '32px',
        width: `calc(100% - ${custom.rightSidebarWidth.desktop} - ${custom.leftSidebarWidth.desktop})`,
        backgroundColor: 'brand.gray25',
        position: 'fixed',
        zIndex: 5,
        paddingTop: '0px',
        '@media (max-width: 1024px)': {
          padding: `0 24px 0`,
          width: `calc(100% - ${custom.rightSidebarWidth.mobile})`,
          left: '0px',
          top: '72px',
        },
        '@media (max-width: 768px)': {
          padding: `0 24px 0`,
          width: `100%`,
        },
      },
      tabpanels: {
        width: '100%',
      },
      tabpanel: {
        marginTop: '0px',
        marginBottom: '0px',
        paddingLeft: '0px',
        paddingRight: '0',
        pl: '0',
        paddingTop: 0,
      },
      tab: {
        maxWidth: '271px',
        minWidth: '100px',

        '@media (max-width: 1279px)': {
          maxWidth: '50%',
          minWidth: '100px',
        },
      },
    }),
    flashcards: () => ({
      root: {
        height: '100%',
        width: '100%',
        position: 'relative',
      },
      tablist: {
        margin: '0px',
        width: `100%`,
        backgroundColor: 'transparent',
        border: 'none',
        paddingBottom: '16px',
        zIndex: 5,
        paddingTop: '0px',
        paddingLeft: '0',
        paddingRight: '0',
      },
      tabpanels: {
        width: '100%',
      },
      tabpanel: {
        marginTop: '0px',
        marginBottom: '0px',
        paddingLeft: '0px',
        paddingRight: '0',
        pl: '0',
        paddingTop: 0,
      },
      tab: {
        maxWidth: '271px',
        minWidth: '100px',

        '@media (max-width: 1279px)': {
          maxWidth: '50%',
          minWidth: '100px',
        },
      },
    }),
    studyHallNestedTab: () => ({
      root: {
        height: '100%',
        width: '100%',
      },
      tablist: {
        paddingTop: '65px',
        maxWidth: custom.layoutMaxSize.header,
        margin: '0 auto',
        position: 'relative',
        width: '100%',
        paddingBottom: '0px',
        paddingRight: 'calc(100% - 566px)',

        '@media (max-width: 1279px)': {
          width: '100%',
          paddingRight: '24px',
        },
        '@media (max-width: 1024px)': {
          paddingRight: '38px',
          pl: '38px',
        },
      },
      tab: {
        maxWidth: '100%',
        minWidth: '100px',
      },
      tabpanels: {
        margin: `${custom.space.xl} auto 0`,
        maxWidth: '1064px',
        width: `calc(100% - ${custom.rightSidebarWidth.desktop} - ${custom.leftSidebarWidth.desktop})`,
        '@media (max-width: 1024px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.mobile}) !important`,
        },
        '@media (max-width: 768px)': {
          paddingLeft: '16px !important',
          paddingRight: '16px !important',
          width: `100% !important`,
        },
      },
      tabpanel: {
        padding: 0,
      },
    }),
    studyHallNestedTabPastPapers: () => ({
      root: {
        height: '100%',
        width: '100%',
      },
      tablist: {
        margin: '0 auto',
        padding: '72px 32px 0 !important',
        width: '100% !important',
        maxWidth: custom.layoutMaxSize.header,
        '@media (max-width: 1024px)': {
          padding: `0 24px 0`,
          width: `calc(100% - ${custom.rightSidebarWidth.mobile})`,
          left: '0px',
        },
      },
      tab: {
        maxWidth: '200px',
        minWidth: '100px',

        '@media (max-width: 1279px)': {
          maxWidth: '100%',
          minWidth: '100px',
        },
        '@media (max-width: 768px)': {
          maxWidth: '100%',
          minWidth: 'auto !important',
        },
      },
      tabpanels: {
        margin: `${custom.space.xl} auto 0`,
        maxWidth: '1064px',
        width: `calc(100% - ${custom.rightSidebarWidth.desktop} - ${custom.leftSidebarWidth.desktop})`,
        '@media (max-width: 1024px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.mobile}) !important`,
        },
        '@media (max-width: 768px)': {
          paddingLeft: '16px !important',
          paddingRight: '16px !important',
          width: `100% !important`,
        },
      },
      tabpanel: {
        padding: 0,
      },
    }),
    firstLevelNestedTab: () => ({
      root: {
        height: '100%',
        width: '100%',
      },
      tablist: {
        paddingTop: `${custom.space.xl}`,
        marginBottom: `${custom.space.xxl}`,
        paddingRight: '0px',
        marginLeft: '-24px',
        border: 'none',
      },
      tabpanels: {},
      tab: {
        maxWidth: '100%',
      },
      tabpanel: {
        padding: 0,
      },
    }),
    login: () => ({
      root: {
        height: '100%',
        width: '100%',
      },
      tablist: {
        width: '100%',
        padding: '0px !important',
        marginTop: `${custom.space.xl}`,
        marginBottom: `${custom.space.xxl}`,
      },
      tab: {
        minWidth: '50%',
      },
      tabpanel: {
        padding: '0px',
      },
    }),
    syllabi: () => ({
      root: {
        height: '100%',
        width: '100%',
      },
      tablist: {
        width: '100%',
        padding: '0px !important',
        marginTop: `${custom.space.xl}`,
        marginBottom: `0`,
      },
      tab: {
        minWidth: '50%',
        maxWidth: '50%',
      },
      tabpanel: {
        padding: '0px',
      },
    }),
    schoolData: () => ({
      root: {
        height: '100%',
        width: '100%',
        root: { position: 'relative' },
      },
      tab: {
        maxWidth: '271px',
        width: '100%',
        '@media (max-width: 1194px)': {
          maxWidth: `50% !important`,
        },
      },
      tablist: {
        marginBottom: `${custom.space.xxl}`,
        paddingLeft: '32px',
        paddingRight: '32px',
        width: '100%',
        backgroundColor: 'brand.gray25',
        position: 'fixed',
        zIndex: 5,
        '@media (max-width: 1194px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.desktop} - ${custom.leftSidebarWidth.desktop})`,
        },
        '@media (max-width: 1024px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.mobile}) !important`,
        },
        '@media (max-width: 768px)': {
          width: `100% !important`,
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
      tabpanels: {
        marginTop: `${custom.space.xl}`,
        paddingLeft: '32px',
        paddingRight: '32px',

        '@media (max-width: 680px)': {
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
      tabpanel: {
        padding: '0px',
        pl: '0px',
        width: '100%',
        margin: '0 auto',
      },
    }),
    questionsSideBar: () => ({
      root: {
        height: '100%',
        paddingLeft: `${custom.space.xl}`,
        paddingRight: `${custom.space.xxl}`,
      },

      tablist: {
        marginTop: `${custom.space.xl}`,
        marginBottom: `${custom.space.xxl}`,
      },
      tabpanels: {
        marginTop: `${custom.space.xl}`,
        height: '90%',
      },
      tabpanel: {
        padding: '0px',
        height: '100%',
      },
    }),
    studyGroup: () => ({
      root: {
        height: '100%',
        width: '100%',
        position: 'relative',
      },

      tablist: {
        width: `calc(100% - ${custom.rightSidebarWidth.desktop})`,
        padding: `0 ${custom.space.xxl} 0px !important`,
        backgroundColor: 'brand.gray25',
        position: 'fixed',
        zIndex: 5,
        '@media (max-width: 1194px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.desktop} - ${custom.leftSidebarWidth.desktop})`,
        },
        '@media (max-width: 1024px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.mobile}) !important`,
        },
        '@media (max-width: 768px)': {
          width: `100% !important`,
        },
      },
      tab: {
        '@media (max-width: 1194px)': {
          maxWidth: `50%`,
        },
      },
      tabpanels: {
        marginTop: '72px',
        width: '100%',
        padding: `0px ${custom.space.xxl} 0px`,
      },
      tabpanel: {
        paddingLeft: '0px',
        paddingRight: '0px',
        pl: '0px',
        paddingTop: 0,
      },
    }),
    examsData: () => ({
      root: {
        height: '100%',
        width: '100%',
        marginTop: `${custom.space.lg}`,
        paddingTop: 0,
      },
      tablist: {
        paddingTop: 0,
        marginBottom: 0,
      },
      tabpanels: {
        marginTop: `${custom.space.xl}`,
        padding: 0,
      },
      tabpanel: {
        padding: 0,
      },
    }),
    library: () => ({
      root: {
        height: '100%',
        width: '100%',
        position: 'relative',
      },

      tablist: {
        width: 'calc(100% - 245px - 131px)',
        padding: `${custom.space.xxl} ${custom.space.xl} 0px`,
        backgroundColor: 'brand.gray25',
        position: 'fixed',
        zIndex: 5,
      },

      tabpanels: {
        marginTop: '72px',
        width: '100%',
        padding: `0px ${custom.space.xl} 0px ${custom.space.xl}`,
      },
      tabpanel: {
        paddingLeft: '0px',
        paddingRight: '0px',
        pl: '0px',
        paddingTop: 0,
      },
    }),
    dashboardPanel: () => ({
      root: {
        height: '100%',
        width: '100%',
        marginTop: `${custom.space.lg}`,
        padding: 0,
      },
      tablist: {
        padding: '0 !important',
        marginBottom: 8,
        borderBottom: 'none',
      },
      tab: {
        maxWidth: '100%',
      },
      tabpanels: {
        marginTop: `${custom.space.xl}`,
        padding: 0,
      },
      tabpanel: {
        padding: 0,
      },
    }),
    resourcesPanel: () => ({
      root: {
        height: '100%',
        width: '100%',
        root: { position: 'relative' },
      },
      tab: {
        maxWidth: '271px',
        minWidth: '100px',
        '@media (max-width: 1194px)': {
          maxWidth: `50%`,
        },
      },
      tablist: {
        marginBottom: `${custom.space.xxl}`,
        paddingLeft: '32px',
        paddingRight: '32px',
        width: '100%',
        backgroundColor: 'brand.gray25',
        position: 'fixed',
        zIndex: 5,
        '@media (max-width: 1194px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.desktop} - ${custom.leftSidebarWidth.desktop})`,
          padding: `0 33px 0px !important`,
        },
        '@media (max-width: 1024px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.mobile}) !important`,
          padding: `0px 33px 0px !important`,
          paddingTop: '0 !important',
        },
        '@media (max-width: 768px)': {
          width: `100% !important`,
        },
      },
      tabpanels: {
        width: '100%',
        marginTop: `${custom.space.xl}`,
      },
      tabpanel: {
        padding: '0px',
        pl: '0px',
      },
    }),
    userSettingsPanel: () => ({
      root: {
        height: '100%',
        width: '100%',
      },
      tablist: {
        marginLeft: '0',
        paddingTop: '65px',
        paddingBottom: '0px',
        paddingRight: '24px !important',
        pl: '24px !important',

        '@media (max-width: 1279px)': {
          width: '100%',
          paddingRight: '33px !important',
          pl: '33px !important',
        },

        '@media (max-width: 1024px)': {
          paddingRight: '38px',
          pl: '38px',
        },
        '@media (max-width: 768px)': {
          width: `100% !important`,
        },
      },
      tab: {
        maxWidth: '210px',
        minWidth: '100px',
        '@media (max-width: 1279px)': {
          maxWidth: `100%`,
          pl: '0px',
          paddingRight: '0px',
        },
      },
      tabpanels: {
        marginTop: `${custom.space.xl}`,
      },
      tabpanel: {
        padding: 0,
      },
    }),
    teacherDashboardTabs: () => ({
      root: {
        height: '100%',
        width: '100%',
      },
      tablist: {
        margin: '0px',
        paddingTop: '0px',
        backgroundColor: 'brand.gray25',
        position: 'fixed',
        zIndex: 5,
        width: `calc(100% - ${custom.rightSidebarWidth.desktop} - ${custom.leftSidebarWidth.desktop})`,
        paddingLeft: '32px !important',
        paddingRight: '32px !important',

        '@media (max-width: 1279px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.mobile} - ${custom.leftSidebarWidth.desktop})`,
        },
        '@media (max-width: 1024px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.mobile}) !important`,
        },
        '@media (max-width: 768px)': {
          paddingLeft: '16px !important',
          paddingRight: '16px !important',
          width: `100% !important`,
        },
      },

      tabpanels: {
        padding: `${custom.space.xxl}`,
        paddingRight: '32px',

        '@media (max-width: 1281px)': {
          paddingRight: '14px',
        },
        '@media (max-width: 1024px)': {
          paddingRight: '32px',
        },
        '@media (max-width: 768px)': {
          paddingLeft: '16px !important',
          paddingRight: '16px !important',
        },
      },
      tab: {
        maxWidth: '180px',
        '@media (max-width: 1195px)': {
          maxWidth: `50% !important`,
        },
      },
      tabpanel: {
        marginBottom: '0px',
        paddingLeft: '0px',
        paddingRight: '0px',
        pl: '0px',
      },
    }),
    libraryTabs: () => ({
      root: {
        height: '100%',
        width: '100%',
      },
      tablist: {
        margin: '0px',
        paddingTop: '0px',
        backgroundColor: 'brand.gray25',
        position: 'fixed',
        zIndex: 5,
        width: `calc(100% - ${custom.rightSidebarWidth.desktop} - ${custom.leftSidebarWidth.desktop})`,
        paddingLeft: '32px !important',
        paddingRight: '32px !important',

        '@media (max-width: 1279px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.mobile} - ${custom.leftSidebarWidth.desktop})`,
        },
        '@media (max-width: 1024px)': {
          width: `calc(100% - ${custom.rightSidebarWidth.mobile}) !important`,
        },
        '@media (max-width: 768px)': {
          paddingLeft: '16px !important',
          paddingRight: '16px !important',
          width: `100% !important`,
        },
      },

      tabpanels: {
        padding: `0`,
      },
      tab: {
        maxWidth: '270px',
        '@media (max-width: 1195px)': {
          maxWidth: `50% !important`,
        },
      },
      tabpanel: {
        marginBottom: '0px',
        padding: '0',
      },
    }),
    diagnosticPanel: () => ({
      root: {
        height: '100%',
        width: '100%',
      },
      tablist: {
        margin: '0px',
        backgroundColor: 'brand.gray25',
        width: `100%`,
        paddingLeft: '32px !important',
        paddingRight: '32px !important',
      },

      tabpanels: {
        padding: `0`,
      },
      tab: {
        maxWidth: '270px',
        '@media (max-width: 1195px)': {
          maxWidth: `50% !important`,
        },
      },
      tabpanel: {
        marginBottom: '0px',
        padding: '0px',
      },
    }),
    teacherStudyBuilderTabs: teacherPortalTabs('sm'),
    studentSchoolTabs: teacherPortalTabs('sm'),
    studentSubTabs: studentSubTabs(),
  },
  defaultProps: {
    variant: 'secondary',
  },
};
